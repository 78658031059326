<template>
  <div>
    <RouterView class="router-view" v-slot="{ Component = Table }">
      <Transition mode="out-in">
        <component
          :is="currentComponent || Component"
          :dataTable="dataTrainings"
          :descriptionTitle="'Registre um novo treinamento'"
          :periodicityField="comboFields"
          :formCreate="formType"
          @getDataByTraining="getTrainingById"
          @register="addTraining"
          @update="updateTraining"
          @remove="removeTraining"
        />
      </Transition>
    </RouterView>
  </div>

  <FloatButton
    v-if="!setButton"
    @click.prevent="changeComponent(Form)"
    :title="'Adicionar treinamento'"
  />

  <FloatBackToComponent 
    v-else @click.prevent="changeComponent(Table)" 
  />
</template>

<script setup>
import FloatBackToComponent from '../components/_partials/FloatBackToComponent.vue'
import FloatButton from './../components/_partials/FloatShowComponent.vue'
import TrainingService from '../service/training.service.js'
import Form from '../components/NewTraining.vue'
import Table from '../components/Table.vue'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { daysToMonth } from '../utils/_partials/calcDayToMonth'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataTraining } from '../store'
import { onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import * as yup from '../../../validator'

const formType = ref({})
const store = dataTraining
const appStore = storeApp.state
const setButton = ref(false)
const comboFields = ref([''])
const dataTrainings = ref([])
const currentComponent = ref(Table)
const currentItemId = ref(undefined)

const schema = yup.object().shape({
  trainingForm: yup.object().shape({
    name: yup.string().required('Informe o titulo do treinamento').label('Titulo'),
    speaker: yup.string().nullable(),
    workload: yup.string().required('Informe a carga horária'),
    type: yup.string().required('Informe o tipo do treinamento'),
    contentType: yup.string().required('Informe o tipo do conteúdo'),
    periodicity_id: yup.string().required('Informe a periodicidade desse treinamento'),
  })
})

const changeComponent = (componentName) => {
  formType.value = {
    create: true,
    labelButton: 'Adicionar',
    descriptionTitle: 'Registrar novo treinamento'
  }
  store.commit('reset')
  setButton.value = !setButton.value
  currentComponent.value = componentName
}

onMounted(async () => {
  appStore.loading = true
  await Promise.all([getTrainings(), setPeriodicitiesFields()])
  appStore.loading = false
})

async function getTrainings () {
  await TrainingService.getAllTrainings()
    .then((res) => {
      dataTrainings.value = res.data
    })
    .catch((err) => {
      toast(
        'Aviso',
        'Treinamentos não encontrados',
        'warn'
      )
    })
}

async function setPeriodicitiesFields () {
  comboFields.value.length = 0
  await TrainingService.getPeriods().then((res) => {
    res.data.forEach((p) => {
      const setMonths = daysToMonth(p.validity)
      comboFields.value.push({
        label:
          setMonths >= 6
            ? `Período de ${setMonths} meses`
            : `Período de ${setMonths} anos`,
        value: p.id
      })
    })
  })
}

async function getTrainingById (id) {
  appStore.loading = true
  currentItemId.value = id

  changeComponent(Form)

  formType.value = {
    create: false,
    labelButton: 'Atualizar',
    descriptionTitle: 'Atualize seu treinamento'
  }

  await TrainingService.getTrainingsById(id)
    .then((res) => {
      store.state.trainingForm = res.data
    }).catch((err) => {
      toast(
        'Aviso',
        'Dados do treinamento não encontrados',
        'error'
      )
    }).finally(() => {
      appStore.loading = false
    })
}

async function updateTraining (id) {
  appStore.loading = true

  delete store.state.trainingForm.period

  await TrainingService.updateTraining(id, store.state.trainingForm)
    .then(res => {
      if (res.data.success) {
        toast(
          'Sucesso',
          'Dados do treinamento atualizados',
          'success'
        )
      }
      getTrainings()
    }).catch(err => {
      toast(
        'Aviso',
        'Dados do treinamento não atualizados',
        'error'
      )
    }).finally(() => {
      appStore.loading = false
    })
}

async function addTraining () {
  appStore.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store.state))

  if (validate && validate.length > 0) {
    // console.log(validate);
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  await TrainingService.registerTraining(store.state.trainingForm)
    .then((res) => {
      if (res.data.success) {
        toast(
          'Sucesso',
          res.data.message,
          'success'
        )
      }
      
      appStore.loading = false
      getTrainings()
    })
    .catch((err) => {
      appStore.loading = false

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      toast(
        'Aviso',
        'Treinamento não foi regitrado',
        'error'
      )
    })
}

async function removeTraining (id) {
  appStore.loading = true
  const confirm = window.confirm('Deseja realmente remover esse treinamento ?')

  if (confirm) {
    await TrainingService.removeTraining(id)
      .then((res) => {
        if (res.data.success) {
          toast(
            'Sucesso',
            'Treinamento removido',
            'success'
          )
        }
        getTrainings()
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Treinamento não foi removido',
          'error'
        )
      })
      .finally(() => {
        appStore.loading = false
      })
  }
}
</script>
