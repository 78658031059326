<template>
  <div class="ml-1 mt-2 p-2 border border-gray-200 rounded bg-gray-50 inline-block text-left">
    <small class="text-gray-400">
      <label class="font-medium">
        TREINAMENTO:
      </label>
      <label class="text-[#6fb171] text-base font-bold">
        {{ item.name.slice(0, 25).concat('...') }}
      </label>
    </small>

    <br>

    <small class="text-gray-400 flex pt-2 mt-auto mb-auto">
      <label class="font-medium">
        TIPO:
      </label>

      <label class="text-[#6fb171] text-base font-bold ml-1 -mt-1">
        {{ item.type }}
      </label>
    </small>

  </div>

  <button class="flex items-center justify-center bg-red-500 text-white p-2 rounded-full -ml-4 -mt-1 h-5 w-5" @click="removeItem">
    <i class="fas fa-times"></i>
  </button>
</template>

<script>
export default {
  props: {
    item: [Array, Object]
  },
  methods: {
    removeItem () {
      this.$emit('removeItem')
    }
  },
  emits: ['removeItem']
}
</script>

<style scoped></style>
