<template>
  <div>
    <PageTitle sub-title="Gerencie as entregas de EPI's de modo seguro e rápido"> 
      Nova retirada 
    </PageTitle>
    <!-- <ContainerVue> -->
      <Tabs>
        <template #form>
          <Form
            :key="appStore.getters.getFormErrors"
            :description-title="'Registro de nova retirada'"
            :employee="employeeData"
            :stock="stockData"
          >
            <CreateButton
              @click="newWithdraw()"
              :icon="'fa-regular fa-address-card'"
              :label="'Registrar'"
            />
          </Form>
        </template>

        <!-- aba de relatórios -->
        <template #reports>
          <ReportsContainer :employees="employeeDataReport" :reports="reports" />
        </template>

        <!-- aba de histórico -->
        <template #history>
          <GoodTables
            :rows="rows"
          />
        </template>
      </Tabs>
    <!-- </ContainerVue> -->
  </div>
</template>

<script setup>
import EmployeesService from '@/modules/employees/services/employees.service'
import ReportsContainer from '../components/ReportsContainer.vue'
import StockService from '@/modules/stock/services/stock.service'
import GoodTables from './../components/helpers/GoodTables.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import PageTitle from '@/components/Titles/PageTitle.vue'
import Tabs from './../../../components/Tabs/TabsVue.vue'
import ContainerVue from '@/components/Container.vue'
import HomeService from '../services/home.service'
import Form from './../components/Form.vue'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import * as yup from './../../../validator'
import { onMounted, ref } from 'vue'
import { dataHome } from '../store'
import { storeApp } from '@/store'

const store = dataHome
const appStore = storeApp
const employeeData = ref([])
const employeeDataReport = ref([])
const stockData = ref([])
const reports = ref([])
let rows = ref()

const schema = yup.object().shape({
  newWithdraw: yup.object().shape({
    employee: yup.object().required().label('Funcionário'),
    item: yup.array().required().label('Item'),
    reason: yup.string().required('Informe o motivo da retirada').label('Motivo')
  })
})

onMounted(async () => {
  appStore.state.loading = true
  dataHome.dispatch('reset')

  await Promise.all([
    getWithDraws(), // home - separar home da ação de retiradas
    getItems(),
    getEmployeesData()
  ])

  Promise.allSettled([
    getReports(),
    getEmployeesReport()
  ])

  appStore.state.loading = false
})

// lista todos os relatórios
const getReports = async () => {
  await EmployeesService.getReports().then((res) => {
    reports.value = res.data
  })
}

// busca as ultimas retiradas
const getWithDraws = async () => {
  await HomeService.getRecentWithdraw().then(res => {
    rows = res.data
  })
}

// responsavel por listar os valors do combo dos funcionários
const getEmployeesData = async () => {
  const data = []
  await EmployeesService.getAllEmployees().then((res) => {
    res.data.forEach((element) => {
      data.push({
        id: element.id,
        name: element.name,
        role: element.role
      })
    })
  })
  employeeData.value = data
}

// reponsável por buscar os relatórios
const getEmployeesReport = async () => {
  const data = []
  await EmployeesService.getAllEmployeesWithTrashed().then((res) => {
    res.data.forEach((element) => {
      data.push({
        id: element.id,
        name: element.name
      })
    })
  })
  employeeDataReport.value = data
}

// responsável por listar os produtos no combo
const getItems = async () => {
  const data = []
  await StockService.getAllItens().then((res) => {
    res.data.forEach((element) => {
      // define os dados que aparecerão nos cards
      data.push({
        id: element.id,
        name: element.name,
        size: element.size ?? null
      })
    })
  })
  stockData.value = data
}

// registra uma nova retirada
async function newWithdraw () {
  appStore.state.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store.state))

  if (validate && validate.length > 0) {
    // console.log(validate);
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await HomeService.newWithdraw(store.state.newWithdraw)
    .then((res) => {
      appStore.state.loading = false

      if (res.data.info) {
        return toast(
          'Protege informa: ',
          res.data.message,
          'info'
        )
      }

      if (res.data.success) {
        delete store.state.newWithdraw.itensSelected

        toast(
          'Sucesso',
          res.data.message,
          'success'
        )

        dataHome.dispatch('reset')
        dataHome.dispatch('cleanCollect')

        Promise.allSettled([
          getWithDraws(),
          getReports()
        ])
      }
    })
    .catch((err) => {
      appStore.state.loading = false

      if (err?.response?.data?.error && err.response.data.status === 404) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      toast(
        'Aviso',
        'Algo deu errado ao solicitar EPI',
        'error'
      )
    })
}

</script>
