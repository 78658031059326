import { showToast } from '@/helpers/UseToasts'
import axios from 'axios'

export class PdfCreate {
  constructor (apiUrl) {
    this.apiUrl = apiUrl
    this.toast = showToast
  }

  async fetchPdf (requestData = null) {
    try {
      const response = await axios({
        url: this.apiUrl,
        method: 'POST',
        data: requestData,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
          Authorization: localStorage.getItem('__access'),
          __company_id: localStorage.getItem('__company')
        }
      })

      const url = window.URL.createObjectURL(response.data)
      this.displayPdf(url)
    } catch (error) {
      // console.log(error)
      if(error.response.data.error && error.response.data.status === 404 )
        return this.toast(
          'Aviso',
          error.response.data.message,
          'error'
        )

      return this.toast(
        'Aviso',
        'Reatório não foi emitido',
        'error'
      )
    }
  }

  displayPdf (pdfUrl) {
    window.open(pdfUrl, '_blank')
  }
}
