<template>
  <div
    class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
  >
    <div class="text-left">
      <p class="mb-4 font-medium text-lg text-gray-800">Notificações</p>
      <p class="text-gray-500 flex">
        <InfoVue
          :label="'Se nenhum e-mail for informado, seu e-mail de usuario será usado'"
        />
        Adicione um e-mail e um periodo para recber notificações e atualizações
        da sua empresa
      </p>
    </div>

    <div class="lg:col-span-2 mt-3 md:mt-0">
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

        <!-- email -->
        <div class="md:col-span-3">
          <BaseInput
            v-model="store.notifications.email"
            type="email"
            name="notifications.email"
            :info="'Informe seu melhor e-mail'"
            label="Informe seu e-email"
            :required-field="true"
          >
            <template #append>
              <CleanInputButton @click="store.notifications.email = undefined" />
            </template>
          </BaseInput>

          <span name="notifications.email" class="text-red-600 text-sm" />
        </div>

        <!-- periodo -->
        <div class="md:col-span-2">
          <div class="flex">
            <BaseInput
              v-model="store.notifications.period"
              type="number"
              name="notifications.period"
              :info="'Notificar-me a cada: '"
              label="Periodo em dias"
              :required-field="true"
            >
              <template #append>
                <CleanInputButton @click="store.notifications.period = undefined" />
              </template>
            </BaseInput>

            <label class="mt-auto mb-auto text-lg pt-3" v-if="store.notifications.period">
              dias
            </label>
          </div>

          <span name="notifications.period" class="text-red-600 text-sm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from './../component/CleanInputButton.vue'
import InfoVue from './../../../components/tooltip/InfoVue.vue'
import { dataUser } from '../store/'
import BaseInput from '@/components/Inputs/BaseInput.vue';

const store = dataUser.state

</script>
