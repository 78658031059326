<template>
  <table
    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
  >
    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
      <tr>
        <slot name="head" />
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(row, index) in data"
        :key="index"
        class="bg-white border-b hover:bg-gray-50"
      >
        <!-- foto, nome e função -->
        <th
          scope="row"
          class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
        >
          <!-- foto -->
          <img
            class="w-10 h-10 rounded-full"
            :src="
              !row['employee_photo']
                ? defaultImg
                : imageServe + row['employee_photo']
            "
            alt="Jese image"
          />

          <!-- nome e função -->
          <div class="ps-3">
            <div class="text-base font-semibold">
              {{ row["employee_name"] }}
            </div>

            <div class="font-normal text-gray-500">
              {{ row["employee_role"] }}
            </div>
          </div>
        </th>

        <!-- tipo -->
        <td scope="row" class="px-6 py-4 whitespace-nowrap">
          <!-- nome do documento e tipo -->
          <div>
            <div
              :title="row['docOriginalName']"
              class="text-base font-semibold"
            >
              {{
                row["docOriginalName"]?.length > 20
                  ? row["docOriginalName"].slice(0, 20).concat("...")
                  : row["docOriginalName"]
                ?? 
                row["reason"]
              }}
            </div>

            <div class="font-normal text-gray-500 lowercase">
              {{ 
                row["key"]?.length > 20
                  ? row["key"].slice(0, 20).concat("...")
                  : row["key"]
              }}
            </div>
          </div>
        </td>

        <!-- status -->
        <td class="px-1 py-4">
          <div class="flex items-center">
            <div
              class="h-2.5 w-2.5 rounded-full me-2"
              :class="[
                row['status'] === 'vencido' ? 'bg-red-500' : '',
                row['status'] === 'a vencer' ? 'bg-yellow-500' : '',
                row['status'] === 'em dia' ? 'bg-green-500' : '',
              ]"
            ></div>
            {{ row["status"] }}
          </div>
        </td>

        <!-- vencimento -->
        <td class="px-6 py-4">
          <div class="flex items-center">
            <div
              class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"
              :class="[
                row['status'] === 'vencido' ? 'bg-red-500' : '',
                row['status'] === 'a vencer' ? 'bg-yellow-500' : '',
                row['status'] === 'em dia' ? 'bg-green-500' : '',
              ]"
            ></div>
            {{ row["expired_at"] }}
          </div>
        </td>

        <!-- ações -->
        <td class="px-6 py-4">
          <div class="flex justify-center font-medium">
            <!-- Remover documento -->
            <a
              title="Remover documento"
              class="text-red-600 mr-1 hover:underline cursor-pointer"
              @click="deleteDoc(row['doc'], row['id'])"
            >
              <component :is="PhTrash" class="h-6 w-6" />
            </a>

            <!-- Ver documento -->
            <a
              target="_blank"
              title="Ver documento"
              :href="imageServe + row['doc']"
              class="text-green-600 ml-1 hover:underline"
              v-if="row['doc']"
            >
              <component :is="PhFileDoc" class="h-6 w-6" />
            </a>

            <a
              title="Nenhum documento anexado"
              class="text-gray-500 ml-1 hover:underline"
              v-else
            >
              <component :is="PhFileDashed" class="h-6 w-6" />
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { PhFileDashed, PhFileDoc, PhTrash } from '@phosphor-icons/vue';
import { defineProps } from 'vue'

const imageServe = process.env.VUE_APP_IMAGE_SERVE + "storage/";
const defaultImg = require('@/assets/logo/icon.png');
const props = defineProps({
  data: [Object, Array]
})

const emit = defineEmits(['delete'])

function deleteDoc(doc, id) {
  emit('delete', doc, id)
}

</script>
