<template>
  <div
    class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
  >
    <div class="text-left">
      <p class="mb-4 font-medium text-lg text-gray-800">Vencimento Interno</p>
      <p class="text-gray-500 flex">
        <InfoVue
          :label="'Se nenhum periodo for informado, será definido um periodo de 15 dias'"
        />
        Informe uma data para realizar a troca desse epi após sua retirada
      </p>
    </div>

    <div class="lg:col-span-2 mt-3 md:mt-0">
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

        <!-- periodo -->
        <div class="md:col-span-3">
          <div class="flex">
            <BaseInput
              v-model="store.item.periodicity"
              type="number"
              min="1"
              name="notifications.period"
              :info="'Realizar a troca desse item em: '"
              label="Periodo em dias"
              :required-field="true"
            >
              <template #append>
                <CleanInputButton 
                  @click="store.item.periodicity = undefined" 
                />
              </template>
            </BaseInput>

            <label class="mt-auto mb-auto text-lg pt-3" v-if="store.item.periodicity">
              dias
            </label>
          </div>

          <span name="item.periodicity" class="text-red-600 text-sm" />
        </div>

      </div>
    </div>

  </div>
</template>

<script setup>
import InfoVue from './../../../components/tooltip/InfoVue.vue'
import { dataStock } from '../store/'
import InputSelect from '@/components/Select/InputSelect.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue';
import CleanInputButton from '../components/CleanInputButton.vue';

const store = dataStock.state

const props = defineProps({
  comboOptions: [Array, Object]
})

</script>
