export const contentType = [
  {
    label: 'Teórico',
    value: 'theoretical'
  },
  {
    label: 'Prático',
    value: 'practical'
  },
  {
    label: 'Teórico e Prático',
    value: 'theoretical_and_practical'
  }
]

export const category = [
  {
    label: 'INICIAL',
    value: 'initial'
  },
  {
    label: 'RECICLAGEM',
    value: 'recicle'
  },
  {
    label: 'CAPACITAÇÃO',
    value: 'capacitation'
  },
  {
    label: 'MULTIPLICADOR',
    value: 'multiplyer'
  }
]

export const period = [
  {
    id: '1 dia',
    name: '1 dia'
  },
  {
    id: '2 dia',
    name: '2 dias'
  },
  {
    id: '3 dia',
    name: '3 dias'
  },
  {
    id: '4 dia',
    name: '4 dias'
  },
  {
    id: '5 dia',
    name: '5 dias'
  },
  {
    id: '6 dia',
    name: '6 dias'
  },
  {
    id: '7 dia',
    name: '7 dias'
  },
  {
    id: 'mais de 7 dias',
    name: 'mais de 7 dias'
  },
]

class Lookups {
  addCurrentValue (value) {
    const currentPeriod = {
      label: `Periodo atual: ${value} dias`,
      value: Number(value)
    }
    periodicityField[6] = currentPeriod

    return periodicityField
  }
}

export default Lookups
