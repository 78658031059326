<template>
  <div
    v-if="dataRoute.subMenu"
    class="mb-2"
  >
    <li>
      <div
        class="flex p-3 hover:bg-[#f8f8f8] rounded-2xl cursor-pointer font-semibold"
        :class="[!storeApp.state.menuOpen ? 'justify-center pl-0 pr-0' : 'pl-3 pr-3']"
        @mouseenter="toggleMenu(dataRoute.title)"
        @click="toggleMenu(dataRoute.title)"
      >
        <div
          :class="storeApp.state.selectedMenu == dataRoute.title ? 'bg-[#e4e4e4] p-1 -ml-1 rounded-full text-[#4d9d2d]' : ''"
        >
          <component :is="dataRoute.icon" class="h-6 w-6" />
        </div>
        <span v-if="storeApp.state.menuOpen" class="ml-4">
          {{ dataRoute.title }}
        </span>
        <ChevronDownIcon
          v-if="storeApp.state.menuOpen"
          :class="{'rotate-180': storeApp.state.activeSubMenu === dataRoute.title}"
          class="h-4 w-4 transition-transform transform duration-300 ease-in-out ml-auto mt-auto mb-auto"
        />
      </div>
    </li>

    <!-- sub menus -->
    <Transition name="slide-down" mode="out-in">
      <ul v-if="storeApp.state.menuOpen && storeApp.state.activeSubMenu === dataRoute.title" class="ml-4">
        <div v-for="(item, index) in dataRoute.subMenu">

          <router-link
            :to="item?.disabled == true ? '' : item.route"
            :key="index"
            class="router-link-exact-active flex items-center hover:bg-[#f8f8f8] p-3 rounded-2xl cursor-pointer"
            :class="[
              item?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            ]"
            @click="setActivatedMenu(dataRoute.title)"
          >
            <component
              :is="item.icon"
              class="h-6 w-6"
              :class="[
                item.disabled ? 'text-gray-400' : ''
              ]"
            />

            <span class="ml-4 text-gray-400 text-sm" v-if="item.disabled">
              {{ item.label }} <small class="p-1 rounded-full bg-[#90ee6b55]"> em breve </small>
            </span>

            <span v-else class="ml-4">
              {{ item.label }}
            </span>
          </router-link>

        </div>
      </ul>
    </Transition>
  </div>

  <div v-else class="mb-2">
    <li @mouseover="toggleMenu(dataRoute.label)">
      <router-link
        :to="dataRoute.route"
        class="flex items-center p-3 pl-0 pr-0 hover:bg-[#f8f8f8] rounded-2xl cursor-pointer font-semibold"
        :class="[!storeApp.state.menuOpen ? 'justify-center pl-0 pr-0' : 'pl-3 pr-3']"
        @click="setActivatedMenu(dataRoute.label)"
      >
          <div
            :class="storeApp.state.selectedMenu == dataRoute.label ? 'bg-[#e4e4e4] p-1 -ml-1 rounded-full' : ''"
          >
            <component
              :is="dataRoute.icon"
              class="h-6 w-6"
            />
          </div>
          <span v-if="storeApp.state.menuOpen" class="ml-4">{{ dataRoute.label }}</span>
        <!-- </div> -->
      </router-link>
    </li>
    <!-- {{ storeApp.state.selectedMenu }} -->
  </div>
</template>

<script>
import { storeApp } from '@/store'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ChevronDownIcon
  },
  props: {
    dataRoute: {
      Object
    }
  },
  data () {
    return {
      activeMenu: null,
      storeApp
    }
  },
  computed: {
    rotateClass () {
      return this.isSubMenuVisible ? 'rotate-icon-up' : 'rotate-icon-down'
    }
  },
  methods: {
    toggleMenu (menu) {
      if (this.storeApp.state.activeMenu === menu) {
        storeApp.commit('clearActiveSubMenu')
      } else {
        storeApp.commit('setActiveSubMenu', menu)
      }
    },
    setActivatedMenu (val) {
      this.storeApp.dispatch('setMenuExpanded', false)
      this.storeApp.dispatch('setActivatedMenu', val)
    }
  }
}
</script>

<style scoped lang="scss">
.router-link-active {
  color: #4d9d2d !important;
  font-weight: 600;
}
/* Estilo para suavizar a transição de altura do submenu */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 500px; /* Ajuste o valor conforme a necessidade do conteúdo */
  opacity: 1;
}
</style>
