import { http } from '@/http'

const PATH = 'app/trainings/'

class TrainingService {
  static async getAllTrainings () {
    return http.get(PATH.concat('all'))
  }

  static async getTrainingsById (id) {
    return http.get(PATH.concat(id))
  }

  static async getPeriods () {
    return http.get(PATH.concat('periods'))
  }

  static async assignTraining (data) {
    return http.post(PATH.concat('assigned-training'), data)
  }

  static async registerTraining (data) {
    return http.post(PATH.concat('register'), data)
  }

  static async updateTraining (id, data) {
    return http.post(PATH.concat(id).concat('/update'), data)
  }

  static async removeTraining (id) {
    return http.delete(PATH.concat(id).concat('/remove'))
  }

  static async removeTrainingByEmployee (id) {
    return http.delete(PATH.concat(id).concat('/remove-by-employee'))
  }
}

export default TrainingService
