import AsosList from '../layout/pages/AsosList.vue'
import UploadForm from '../layout/pages/UploadForm.vue'
import { createStore } from 'vuex'
import { markRaw } from 'vue'

export const dataAso = createStore({
  state: () => {
    return {
      docs: [], // list
      componentActive: markRaw(AsosList),
      employees: [], // combo
      afastamento: [], // combo
      form: {
        realizado_em: undefined,
        expired_at: undefined,
        employee: undefined,
        type: undefined,
        doc: undefined,
        docName: undefined,
        docOriginalName: undefined,
      },
      afastamento_form: {
        employee: undefined,
        realizado_em: undefined,
        expired_at: undefined,
        type: undefined,
        reason: undefined,
        cid: undefined,
        docName: undefined,
        doc: undefined,
      }
    }
  },
  getters: {
    getActiveComponent: (state) => markRaw(state.componentActive),
    getEmployees: (state) => state.employees,
    getAfastamento: (state) => state.afastamento,
    getDocs: (state) => state.docs,
  },
  mutations: {
    RESET_FORM(state) {
      state.form.realizado_em = undefined
      state.form.expired_at = undefined
      state.form.employee = undefined
      state.form.type = undefined
      state.form.doc = undefined
      state.form.docName = undefined
      state.form.docOriginalName = undefined
      
      state.afastamento_form.employee = undefined
      state.afastamento_form.realizado_em = undefined
      state.afastamento_form.expired_at = undefined
      state.afastamento_form.type = undefined
      state.afastamento_form.reason = undefined
      state.afastamento_form.cid = undefined
      state.afastamento_form.docName = undefined
      state.afastamento_form.doc = undefined

    },
    SET_FORM_COMPONENT_ACTIVE(state) {
      state.componentActive = markRaw(UploadForm)
    },
    SET_LIST_COMPONENT_ACTIVE(state) {
      state.componentActive = markRaw(AsosList)
    },
    SET_ALL_DOCS(state, value) {
      state.docs = []
      state.docs = value
    },
    SET_EMPLOYEES(state, value) {
      state.employees = value
    },
    SET_AFASTAMENTO(state, value) {
      state.afastamento = value
    },
  },
  actions: {
    resetForm({ commit }) {
      commit('RESET_FORM')
    },
    setAllDocs({ commit }, val) {
      commit('SET_ALL_DOCS', val)
    },
    setFormComponentActive({ commit }) {
      commit('SET_FORM_COMPONENT_ACTIVE');
    },
    setListComponentActive({ commit }) {
      commit('SET_LIST_COMPONENT_ACTIVE');
    },
    setEmployees({ commit }, value) {
      commit('SET_EMPLOYEES', value)
    },
    setAfastamento({ commit }, value) {
      commit('SET_AFASTAMENTO', value)
    },
  }
})
