<template>
  <div>
    <PageTitleVue> Adicionar Item </PageTitleVue>
    <!-- <ContainerVue class="webkit"> -->
      <Form :description-title="'Detalhes do item'">
        <NotifyForm :combo-options="combobox" :key="combobox" />
        <div class="w-full flex justify-center mt-8">
          <CreateButton
            @click="addItem()"
            :key="buttonLabel"
            :label="buttonLabel"
          />
        </div>
      </Form>
    <!-- </ContainerVue> -->
    <FloatBackPage />
  </div>
</template>

<script setup>
import * as yup from './../../../validator'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import ContainerVue from '@/components/Container.vue'
import Form from './Form.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import StockService from '../services/stock.service'
import NotifyForm from './NotifyForm.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import Lookups from './lookups'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import { onBeforeMount, ref } from 'vue'
import { dataStock } from '../store'
import { storeApp } from '@/store'

const appStore = storeApp.state
const store = dataStock
const lookups = new Lookups()
let combobox = ref([])
const errors = ref([])
const confirmUpdateItenData = ref('false')
const buttonLabel = ref('Cadastrar')
const oldValueField = ref('')

const schema = yup.object().shape({
  item: yup.object().shape({
    name: yup.string().required().label('Nome do item').min(3),
    amount: yup.string().required('Informe qtd. em estoque').label('Estoque'),
    periodicity: yup
      .string()
      .test('is-not-zero', 'O número não pode ser zero', (value) => value !== 0)
      .required('Informe um periodo')
      .label('Periodo'),
    approvalCertificate: yup.object().shape({
      number: yup.string().label('CA').nullable(),
      validity: yup.string().label('Validade do CA').nullable()
    })
  })
})

onBeforeMount(() => {
  combobox = lookups.getComboPeriodicity().periodicityField
  store.commit('reset')
})

async function addItem () {
  store.state.item.periodicity =
    store.state.item.periodicity == undefined ? 15 : store.state.item.periodicity

  appStore.loading = true
  cleanAllFormFields()

  const validate = (await validateFormField(schema, store.state))

  if (validate && validate.length > 0) {
    // console.log(validate);
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  // fluxo da imagem
  if (
    store.state.item.photo &&
    typeof store.state.item.photo === 'object' &&
    typeof store.state.item.imgPath === 'string' &&
    store.state.item.imgPath !== '[]'
  ) {
    await StockService.editImage(
      store.state.item.photo,
      store.state.item.imgPath
    )
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de novo arquivo não concluído',
          'error'
        )
      })
  } else if (
    store.state.item.photo &&
    typeof store.state.item.photo !== 'string'
  ) {
    await StockService.saveImage(store.state.item.photo)
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de arquivo não concluído',
          'error'
        )
      })
  } else {
    store.state.item.photo = store.state.item.imgPath
  }

  if (oldValueField.value == store.state.item.approvalCertificate.number) {
    confirmUpdateItenData.value = 'true'
  } else {
    confirmUpdateItenData.value = 'false'
  }

  store.state.item.company_id = localStorage.getItem('__company')

  await StockService.register(store.state.item, confirmUpdateItenData.value)
    .then((res) => {
      appStore.loading = false

      if (res.data.info) {
        // define os campos para envio e atualiza o botão
        oldValueField.value = store.state.item.approvalCertificate.number
        confirmUpdateItenData.value = 'true'
        buttonLabel.value = 'Atualizar'

        return toast(
          'Aviso', 
          res.data.message, 
          'info'
        )
      }

      if (res.data.success) {
        toast(
          'Sucesso',
          res.data.message,
          'success'
        )
      }

      oldValueField.value = ''
      confirmUpdateItenData.value = 'false'
      buttonLabel.value = 'Cadastrar'
      store.commit('reset')
    })
    .catch((err) => {
      appStore.loading = false

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      return toast(
        'Aviso',
        'Algo deu errado ao registrar item',
        'error'
      )
    })
}
</script>

<style lang="scss">
.webkit {
  ::-webkit-scrollbar {
    height: 1px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>
