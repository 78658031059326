<template>
  <div>
    <PageTitleVue> Treinamentos </PageTitleVue>
    <!-- <ContainerVue> -->
      <TraningTabs>
        <template #form>
          <AssignedTrainingVue />
        </template>

        <template #table>
          <TrainingsList />
        </template>

        <template #employee>
          <EmployeesList />
        </template>

      </TraningTabs>
    <!-- </ContainerVue> -->
  </div>
</template>

<script setup>
import employeesService from '../employees/services/employees.service'
import TraningTabs from './components/_partials/TraningTabs.vue'
import AssignedTrainingVue from './layout/AssignedTraining.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
// import TrainingService from './service/training.service'
import TrainingsList from './layout/TrainingsList.vue'
import EmployeesList from './layout/EmployeesList.vue'
import ContainerVue from '@/components/Container.vue'
import { dataTraining } from './store'
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

const store = dataTraining

onMounted(() => {
  getEmployeeTrainings();
})

async function getEmployeeTrainings (){
  Promise.allSettled([  
    employeesService.getAllEmployees()
      .then(res => {
        store.dispatch('setEmployeeTrainings', res.data);
      }).catch(error => {
        console.log(error);
      })
  ]);

}

</script>
