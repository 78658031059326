import CompanyView from '../CompanyView.vue'
import EditCompany from './../layout/UpdateCompany.vue'

export const companyRoute = [
  {
    path: '/company',
    name: 'company',
    alias: '/me-company',
    component: CompanyView
  },
  {
    path: '/company/:id/edit',
    name: 'edit-company',
    component: EditCompany
  }
]
