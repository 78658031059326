<template>
  <div class="p-0 md:p-2 flex flex-col items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div id="form-fields" class="md:p-8 mb-6">
          <div class="bg-white rounded shadow-lg p-4 px-4 md:-mt-6 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left block">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <label class="text-red-500">*</label> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.item.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.item.imgPath"
                  alt="EPI"
                  class="mt-14 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                
                <!-- nome -->
                <div class="md:col-span-5">
                  <BaseInput
                    type="text"
                    name="item.name"
                    v-model="store.item.name"
                    :info="'Nome do item'"
                    label="Informe o nome do EPI"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton @click="store.item.name = undefined" />
                    </template>
                  </BaseInput>

                  <span name="item.name" class="text-red-600 text-sm" />
                </div>

                <!-- descrição -->
                <div class="md:col-span-4">
                  <BaseInput
                    type="text"
                    name="item.description"
                    v-model="store.item.description"
                    :info="'Descrição do item'"
                    label="Descrição"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.item.description = undefined"
                      />
                    </template>
                  </BaseInput>

                </div>

                <!-- tamanho -->
                <div class="md:col-span-1">
                  <BaseInput
                    type="text"
                    name="item.size"
                    v-model="store.item.size"
                    :info="'Tamanho do item'"
                    label="Tamanho do item"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.item.size = undefined"
                      />
                    </template>
                  </BaseInput>

                </div>

                <!-- fornecedor -->
                <div class="md:col-span-3">
                  <BaseInput
                    type="text"
                    name="item.amount"
                    v-model="store.item.supplier"
                    :info="'Fornecedor do item'"
                    label="Fornecedor"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                      @click="store.item.supplier = undefined"
                    />
                    </template>
                  </BaseInput>
                </div>

                <!-- usabilidade -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                    O item é descartável? <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1.5 w-full"
                  >
                    <SimpleSelect
                      :data="itemDisposable"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent text-center"
                      @selected-data="(val) => (store.item.reusable = val.id)"
                    />
                  </div>
                </div>

                <!-- estoque atual -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="item.amount"
                    v-mask="'#####'"
                    v-model="store.item.amount"
                    :info="'Quantidade em estoque'"
                    label="Estoque atual"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton @click="store.item.amount = undefined" />
                    </template>
                  </BaseInput>

                  <span name="item.amount" class="text-red-600 text-sm" />
                </div>

                <!-- estoque mínimo -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="item.min_amount"
                    v-mask="'#####'"
                    v-model="store.item.min_amount"
                    :info="'Quantidade minima no estoque'"
                    label="Estoque minimo"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton @click="store.item.min_amount = undefined" />
                    </template>
                  </BaseInput>

                  <span name="item.min_amount" class="text-red-600 text-sm" />
                </div>

                <!-- valor -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="item.amount"
                    v-model="store.item.unit"
                    :info="'Valor do item'"
                    label="Informe o valor"
                    :required-field="false"
                    :activeDefault="false"
                    @input="formatCurrency"
                  >
                    <template #append>
                      <CleanInputButton @click="store.item.unit = undefined" />
                    </template>
                  </BaseInput>
                </div>

                <!-- fabricante -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="item.maker"
                    v-model="store.item.maker"
                    :info="'Fabricante'"
                    label="Informe o fabricante"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton @click="store.item.maker = undefined" />
                    </template>
                  </BaseInput>

                </div>

                <!-- possui CA? -->
                <div
                  class="md:col-span-1"
                  :class="{ 
                    'md:col-span-2': ! store.item.approvalCertificate.it_has_ca, 
                    'md:col-span-1': store.item.approvalCertificate.it_has_ca }
                  "
                >
                  <label class="flex pl-1 text-xs text-[#2b3038]">
                    O item possui CA?
                  </label>
                  <div class="h-10 flex rounded items-center mt-1">
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :initialValue="store.item.approvalCertificate.it_has_ca ? { name: 'SIM', value: 1 } : { name: 'NÃO', value: 0 }"
                      :data="issetCA"
                      @selected-data="
                        (val) => {
                          if (val?.value == 1){
                            store.item.approvalCertificate.it_has_ca = true;
                          } else {
                            store.item.approvalCertificate.it_has_ca = false;
                            store.item.approvalCertificate.number = undefined
                            store.item.approvalCertificate.validity = undefined
                          }
                      }
                      "
                    />
                  </div>
                </div>

                <!-- sim, possui CA -->
                <Transition mode="out-in" class="lg:col-span-4">
                  <div class="lg:col-span-2" v-if="store.item.approvalCertificate.it_has_ca == true">
                    <div class="grid gap-4 gap-y-2 text-sm grid-cols-2 md:grid-cols">

                      <!-- CA -->
                      <div class="md:col-span-1">
                        <BaseInput
                          name="ca"
                          v-mask="'#####'"
                          v-model="store.item.approvalCertificate.number"
                          :info="'Informe o CA'"
                          label="CA"
                          :required-field="true"
                          :activeDefault="false"
                        >
                          <template #append>
                            <CleanInputButton
                              @click="store.item.approvalCertificate.number = undefined"
                            />
                          </template>
                        </BaseInput>

                        <span name="item.approvalCertificate.number" class="text-red-600 text-sm" />
                      </div>

                      <!-- validade do CA -->
                      <div class="md:col-span-1">
                        <BaseInput
                          type="date"
                          name="validity"
                          v-model="store.item.approvalCertificate.validity"
                          :info="'Validade do CA'"
                          label="Validade do CA"
                          :required-field="true"
                          :activeDefault="true"
                        >
                          <template #append>
                            <CleanInputButton
                              @click="store.item.approvalCertificate.validity = undefined"
                            />
                          </template>
                        </BaseInput>

                        <span name="item.approvalCertificate.validity" class="text-red-600 text-sm" />
                      </div>

                    </div>
                  </div>
                </Transition>

                <!-- fluxo de imagem -->
                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="EPI"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 mt-4 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[8rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <i class="fa-solid fa-helmet-safety"></i>
                    Foto do EPI
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import CleanInputButton from '../components/CleanInputButton.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import { dataStock } from '../store'
import { showToast as toast } from '@/helpers/UseToasts'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    FloatBackPage,
    BaseInput,
    SimpleSelect
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataStock.state
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'
    const issetCA = [
      {
        name: 'SIM',
        value: 1
      },
      {
        name: 'NÃO',
        value: 0
      }
    ]

    return {
      store,
      fileUrl,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined,
      itemDisposable: [
        {
          id: '1',
          name: 'SIM'
        },
        {
          id: '0',
          name: 'NÃO'
        }
      ],
      issetCA
    }
  },
  methods: {
    formatCurrency (event) {
      let rawPrice = ''
      const onlyDigits = event.target.value.replace(/\D/g, '')

      const formatted = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(onlyDigits / 100)

      this.store.item.unit = formatted
      rawPrice = onlyDigits / 100
    },
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        // const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.item.photo = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.item.photo = undefined
          return this.toast(
            'Protege informa: ',
            'Formato inválido, apenas imagens do tipo: webp, png, gif, jpeg e jpg são aceitos',
            'info'
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
