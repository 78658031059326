<template>
  <div class="flex items-center justify-center">
    <div>
      <div class="container max-w-screen-lg mx-auto">
        <div class="md:p-1 mb-6">
          <div
            class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
          >
            <div class="text-left block mb-4">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.imgPath"
                  alt="logo"
                  class="mt-8 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <!-- form -->
            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <!-- nome fantasia -->
                <div class="md:col-span-5">
                  <!-- <label class="text-left flex pl-1" for="name">
                    Nome fantasia <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.company.name"
                      type="text"
                      name="company.name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton @click="store.company.name = undefined" />
                  </div> -->
                  <BaseInput
                    v-model="store.company.name"
                    type="text"
                    name="company.name"
                    :info="'Nome da sua empresa / unidade'"
                    label="Nome da empresa"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.name = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.name" class="text-red-600 text-sm" />
                </div>

                <!-- cnpj -->
                <div class="md:col-span-2">
                  <!-- <label class="text-left flex pl-1" for="company.cnpj">
                    CNPJ <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-mask="'##.###.###/0001-##'"
                      v-model="store.company.cnpj"
                      name="company.cnpj"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton @click="store.company.cnpj = undefined" /> 
                  </div> -->

                  <BaseInput
                    v-mask="'##.###.###/0001-##'"
                    v-model="store.company.cnpj"
                    type="text"
                    name="company.cnpj"
                    :info="'Informe seu CNPJ'"
                    label="Informe CNPJ"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.cnpj = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.cnpj" class="text-red-600 text-sm" />
                </div>

                <!-- cidade -->
                <div class="md:col-span-3">
                  <!-- <label class="text-left flex pl-1" for="cpf">
                    Cidade <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.company.city"
                      name="company.city"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton @click="store.company.city = undefined" />
                  </div> -->

                  <BaseInput
                    v-model="store.company.city"
                    type="text"
                    name="company.city"
                    :info="'Cidade da sua empresa / unidade'"
                    label="Informe sua cidade"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.city = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.city" class="text-red-600 text-sm" />
                </div>

                <!-- bairro -->
                <div class="md:col-span-2">
                  <!-- <label class="text-left flex pl-1" for="cnh">
                    Bairro <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.company.country"
                      type="text"
                      name="company.country"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.company.country = undefined"
                    />
                  </div> -->

                  <BaseInput
                    v-model="store.company.country"
                    type="text"
                    name="company.country"
                    :info="'Informe o bairro'"
                    label="Informe sua cidade"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.country = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.country" class="text-red-600 text-sm" />
                </div>

                <!-- rua -->
                <div class="md:col-span-2">
                  <!-- <label class="text-left flex pl-1" for="cnh">Rua</label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.company.address"
                      type="text"
                      name="company.address"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.company.address = undefined"
                    />
                  </div> -->

                  <BaseInput
                    v-model="store.company.address"
                    type="text"
                    name="company.address"
                    :info="'Qual sua rua?'"
                    label="Informe a rua"
                    :required-field="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.address = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- numero -->
                <div class="md:col-span">
                  <!-- <label class="text-left flex pl-1" for="cnh">N°</label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.company.number"
                      type="number"
                      name="company.number"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.company.number = undefined"
                    />
                  </div> -->

                  <BaseInput
                    v-model="store.company.number"
                    type="number"
                    name="company.number"
                    :info="'Qual o nº?'"
                    label="Informe o nº"
                    :required-field="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.number = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.number" class="text-red-600 text-sm" />
                </div>

                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="logo"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 mt-4 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[10rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <i class="fa-solid fa-building text-blueGray-600"></i>
                    Logo da Empresa
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from "./../component/CleanInputButton.vue";
import InfoVue from "./../../../components/tooltip/InfoVue.vue";
import { dataUser } from "../store/";
import { showToast as toast } from "@/helpers/UseToasts";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  components: {
    CleanInputButton,
    InfoVue,
    BaseInput,
  },
  props: {
    descriptionTitle: String,
  },
  data() {
    const store = dataUser.state;
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + "storage/";

    return {
      store,
      fileUrl,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined,
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedExtensions = ["webp", "png", "gif", "jpeg", "jpg"];
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name;
          this.selectedFileUrl = URL.createObjectURL(file);
          this.store.company.photo = file;
        } else {
          this.selectedFileUrl = undefined;
          this.selectedFileName = undefined;
          this.store.company.photo = undefined;
          return this.toast(
            "Protege informa: ",
            "Tipo de arquivo não é válido",
            "error"
          );
        }
      }
    },
  },
};
</script>
