<template>
  <div>
    <PageTitleVue> Editar Item </PageTitleVue>
    <!-- <ContainerVue class="webkit"> -->
      <Form :description-title="'Editar item'">
        <NotifyForm :combo-options="combobox" :key="combobox" />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="editIten"
            :icon="'fa-solid fa-check'"
            :label="'Salvar'"
          />
        </div>
      </Form>
    <!-- </ContainerVue> -->
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ContainerVue from '@/components/Container.vue'
import StockService from '../services/stock.service'
import NotifyForm from './NotifyForm.vue'
import Lookups from './lookups.js'
import Form from './Form.vue'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import { onBeforeMount, onMounted, ref } from 'vue'
import { showToast as toast } from '@/helpers/UseToasts'
import * as yup from './../../../validator'
import { parse, isValid } from 'date-fns'
import { useRoute } from 'vue-router'
import { dataStock } from '../store'
import { storeApp } from '@/store'

const store = dataStock
const appStore = storeApp.state
const itenId = useRoute()
const lookups = new Lookups()
let combobox = ref([])

const schema = yup.object().shape({
  item: yup.object().shape({
    name: yup.string().required().label('Nome do item').min(3),
    amount: yup.number().required().label('Estoque'),
    periodicity: yup
      .string()
      .test('is-not-zero', 'O número não pode ser zero', (value) => value !== 0)
      .required('Informe um periodo')
      .label('Periodo'),
    approvalCertificate: yup.object().shape({
      number: yup.number().label('CA').nullable(),
      validity: yup.string().label('Validade do CA').nullable()
    })
  })
})

onBeforeMount(async () => {
  store.commit('reset')
  await getIten()
})

async function getIten () {
  appStore.loading = true
  StockService.getItenById(itenId.params.id)
    .then((res) => {
      store.state.item.size = res.data.size

      console.log(
        res.data,
        store.state.item.size
      );
      
      store.state.item.name = res.data.name
      store.state.item.description = res.data.description
      store.state.item.amount = res.data.inventory
      store.state.item.min_amount = res.data.min_amount
      store.state.item.approvalCertificate.it_has_ca = res.data.it_has_ca
      store.state.item.approvalCertificate.number = res.data.CA
      store.state.item.approvalCertificate.validity = res.data.validity
      store.state.item.unit = res.data.unitary_value
      store.state.item.photo = res.data.image
      store.state.item.reusable = res.data.reusable
      store.state.item.periodicity = res.data.periodicity
      store.state.item.maker = res.data.maker
      store.state.item.supplier = res.data.supplier
      store.state.item.imgPath = res.data.image
      store.state.item.company_id = appStore.accessToken.getItem('__company')

      if (store.state.item.periodicity) {
        combobox = lookups.addCurrentValue(store.state.item.periodicity)
      }
      appStore.loading = false
    })
    .catch((err) => {
      appStore.loading = false
      toast(
        'Aviso',
        'Dados do item não encontrados',
        'error'
      )
    })
}

async function editIten () {
  appStore.loading = true

  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  if (
    store.state.item.photo &&
    typeof store.state.item.photo === 'object' &&
    typeof store.state.item.imgPath === 'string' &&
    store.state.item.imgPath !== '[]'
  ) {
    await StockService.editImage(
      store.state.item.photo,
      store.state.item.imgPath
    )
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de novo arquivo não concluído',
          'error'
        )
      })
  } else if (
    store.state.item.photo &&
    typeof store.state.item.photo !== 'string'
  ) {
    await StockService.saveImage(store.state.item.photo)
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de arquivo não concluído',
          'error'
        )
      })
  } else {
    store.state.item.photo = store.state.item.imgPath
  }

  store.state.item.company_id = localStorage.getItem('__company')

  await StockService.editIten(itenId.params.id, store.state.item)
    .then((res) => {
      toast(
        'Sucesso',
        'Dados do item atualizado',
        'success'
      )
      appStore.loading = false
    })
    .catch((err) => {
      toast(
        'Aviso',
        'As alteraçoes não foram salvas',
        'error'
      )
      appStore.loading = false
    })
}
</script>

<style lang="scss">
.webkit {
  ::-webkit-scrollbar {
    height: 1px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>
