<template>
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <table class="w-full">
      <thead>
        <tr>
          <TableHeadField>
            <span class="mb-1.5"> Nome </span>
          </TableHeadField>

          <TableHeadField>
            <span class="mb-1.5"> Tipo </span>
          </TableHeadField>

          <TableHeadField>
            <span class="mb-1.5"> Carga horária </span>
          </TableHeadField>

          <TableHeadField>
            <span class="mb-1.5"> Periodicidade </span>
          </TableHeadField>

          <!-- <TableHeadField>
            <span class="mb-1.5"> Palestrante </span>
          </TableHeadField> -->

          <TableHeadField :text-center="false">
            <span class="mb-1.5"> Ações </span>
          </TableHeadField>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in props.dataTable"
          :key="index"
          class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9"
          :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
        >

          <!-- nome -->
          <td
            class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]"
          >
            <div :class="{ 'text-center': props.center }">
              <span class="pl-2">
                {{ row["name"] }}
              </span>
            </div>
          </td>
          
          <!-- tipo -->
          <td
            class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]"
          >
            <div :class="{ 'text-center': props.center }">
              <span class="pl-2 text-gray-900">
                {{ setLabelToTypeTraining(row["type"]) }}
              </span>
            </div>
          </td>

          <!-- carga horária -->
          <td
            class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]"
          >
            <div :class="{ 'text-center': props.center }">
              <span class="pl-2">
                {{ row["workload"] }} horas
              </span>
            </div>
          </td>

          <!-- periodicidade -->
          <td
            class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]"
          >
            <div :class="{ 'text-center': props.center }">
              <span class="pl-2">
                {{ setPeriod(row["period"].validity)  }}
              </span>
            </div>
          </td>

          <!-- palestrante -->
          <!-- <td
            class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]"
          >
            <div :class="{ 'text-center': props.center }">
              <span class="pl-2">
                {{ row["speaker"] ? row["speaker"] : 'Não informado' }}
              </span>
            </div>
          </td> -->
          
          <td
            class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0"
          >
            <ActionsButton
              @update="updateTraining(props.dataTable[index].id)"
              @delete="deleteTraining(props.dataTable[index].id)"
              @getReport="generateReport(props.dataTable[index].id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import ActionsButton from '@/components/Tables/ActionsButton.vue'
import TableHeadField from '@/components/Tables/TableHeadField.vue'
import { daysToMonth } from '../utils/_partials/calcDayToMonth'
import { defineEmits } from 'vue'

const props = defineProps({
  dataTable: [Object, Array],
  center: { Boolean, default: false }
})

const emit = defineEmits()

function setLabelToTypeTraining (label) {
  if (label == 'initial') { return 'inicial' }
  
  if (label == 'recicle') { return 'reciclagem' }

  if (label == 'capacitation' || label == 'capacitaçao') { return 'capacitação' }

  if (label == 'multiplyer') { return 'multiplicador' }
}

async function generateReport (id) {
  emit('report', id)
}

async function deleteTraining (id) {
  emit('remove', id)
}

async function updateTraining (id) {
  emit('getDataByTraining', id)
}

function setPeriod (period) {
  const periodicity = daysToMonth(period)

  return periodicity >= 6
    ? `${periodicity} meses`
    : `${periodicity} anos`
}
</script>

<style lang="scss" scoped></style>
