<template>
  <section>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg min-h-[25vh] p-2">
      
      <div class="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white">
        <DropDownOptions 
          v-model="selectedFilter"
          :label="'Documentos'" 
          :options="['Todos', 'em dia', 'a vencer', 'vencidos']" 
        />

        <SearchInput v-model="searchTerm" />
      </div>

      <DataTable :data="filteredData" @delete="deleteDocument">
        <template #head>
          <th scope="col" class="px-6 py-3">Funcionário</th>
          <th scope="col" class="px-6 py-3">Tipo</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Vencimento</th>
          <th scope="col" class="px-6 py-3">#</th>
        </template>
      </DataTable>

    </div>
  </section>
</template>

<script setup>
import DropDownOptions from "@/components/Tables/DropDownOptions.vue";
import SearchInput from "@/components/Tables/SearchInput.vue";
import DataTable from "@/components/Tables/DataTable.vue";
import AsosService from "../../service/asos.service";
import moment from "moment";
import { showToast as toast } from '@/helpers/UseToasts'
import { computed, onMounted } from "vue";
import { dataAso } from "./../../store";
import { initFlowbite } from "flowbite";
import { storeApp } from "@/store";
import { ref } from "vue";

const store = dataAso;
const searchTerm = ref("");
const selectedFilter = ref("");

const dataTable = computed(() => {
  return store.getters?.getDocs.map((row) => {
    const valueData = row.value ? JSON.parse(row.value) : {};
    const status = calculateStatus(valueData.expired_at); // Calcular o status com base na data
    const formattedExpiredAt = formatDate(valueData.expired_at); // Formatar a data
    console.log(valueData.expired_at);
    
    return {
      ...row,
      ...valueData,
      expired_at: formattedExpiredAt,
      status,
    };
  });
})

// filtros
const filteredData = computed(() => {
  const searchTermLower = searchTerm.value?.toLowerCase();

  return dataTable.value.filter((row) => {
    if(selectedFilter.value === 'Todos') 
    selectedFilter.value = '';

    const matchesDropdown = !selectedFilter.value || row['status'] === selectedFilter.value;

    const matchesSearch = !searchTerm.value || 
      ['employee_name', 'employee_role', 'docOriginalName', 'expired_at', 'key'].some(
        (key) =>
          row[key] && row[key].toString().toLowerCase().includes(searchTermLower)
      );

    return matchesDropdown && matchesSearch;
  });
});

function formatDate (date) {
  return moment(date).format('D/MM/Y')
}

function calculateStatus(expiredAt) {
  const today = moment();
  const expirationDate = moment(expiredAt);
  const daysDiff = expirationDate.diff(today, "days");

  if (daysDiff < 0) {
    return "vencido";
  } else if (daysDiff <= 30) {
    return "a vencer";
  } else {
    return "em dia";
  }
}

async function deleteDocument (doc, id) {
  storeApp.state.loading = true
  const confirm = window.confirm('Deseja realmente remover este documento?')
  if (confirm) {
    // apaga o doc do servidor
    Promise.allSettled([
      AsosService.removeDoc(doc).catch(err => {
        this.toast(
          'Aviso',
          'Documento ainda disponivel no servidor',
          'info'
        )
      })
    ])

    await AsosService.removeDocRegister(id)
      .then(res => {
        storeApp.state.loading = false

        if (res?.data?.success) {
          dataAso.dispatch('setAllDocs', res?.data?.data)
          
          return toast(
            'Sucesso',
            res?.data?.message,
            'success'
          )
        }
      }).catch(err => {
        storeApp.state.loading = false

        if (err?.response?.data?.error && err.response.data.status === 400) {
          return toast(
            'Aviso',
            err.response.data.message,
            'error'
          )
        }

        return toast(
          'Aviso',
          'Algo deu errado ao remover registrar',
          'error'
        )
      })
  }
  storeApp.state.loading = false
}

onMounted(() => {
  initFlowbite();
});
</script>
