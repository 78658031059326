<template>
  <div 
    class="border-b ml-5 "
    :class="[
      props.left ? 'mr-auto text-left' : 'ml-auto text-right',
      props.left ? 'mr-auto text-left' : 'ml-auto text-right',
      props.w25 ? 'w-[60%] md:w-1/3' : 'w-[80%] md:w-1/2',
    ]"
  >
    <h2 class="text-2xl font-bold">
      <slot />
    </h2>
    <small>
      {{ label }}
    </small>
  </div>
</template>

<script setup>

const props = defineProps({
  left: {Boolean, default: true},
  label: {String, required: false},
  w25: {Boolean, default: false},
})
</script>
