export const docType = [
  {
    name: 'ADMISSIONAL',
    value: 'admissional'
  },
  {
    name: 'PERIÓDICO',
    value: 'periodico'
  },
  {
    name: 'RETORNO AO TRABALHO',
    value: 'retorno_ao_trabalho'
  },
  {
    name: 'MUDANÇA DE RISCO',
    value: 'mudança_de_risco'
  },
  {
    name: 'MONITORAÇÃO PONTUAL',
    value: 'monitoracao_pontual'
  },
  {
    name: 'DEMISSIONAL',
    value: 'demissional'
  },
  {
    name: 'NÃO INFORMADO',
    value: 'nao_informado-'
  },
]

export const afastamentoType = [
  {
    name: 'NÃO INFORMADO',
    value: 'não informado'
  },
  {
    name: 'ATROPELAMENTO',
    value: 'atropelamento'
  },
  {
    name: 'COLISÃO',
    value: 'colisão'
  },
  {
    name: 'OUTROS',
    value: 'outros'
  }
]

// class Lookups {
//   addCurrentValue (value) {
//     const currentPeriod = {
//       label: `Periodo atual: ${value} dias`,
//       value: Number(value)
//     }
//     periodicityField[6] = currentPeriod

//     return periodicityField
//   }
// }

export default { docType, afastamentoType }
