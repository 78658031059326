<template>
  <div class="flex flex-col items-center justify-start min-h-[75vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 top-0">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

            <!-- header / descript-->
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-900">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div
              :key="homeStore.getters.itensSelected"
              id="form-fields"
              class="lg:col-span-2"
            >
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                <!-- funcionário -->
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="newWithdraw.employee">
                    Funcionário <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="employee"
                      @selected-data="
                        (val) => (store.newWithdraw.employee = val)
                      "
                    />
                  </div>

                  <span name="newWithdraw.employee" class="text-red-600 text-sm"/>
                </div>

                <!-- item -->
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="newWithdraw.item">
                    Informe o(s) item <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      :data="stock"
                      @selected-data="(val) => mountItemsToSend(val)"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                    />
                  </div>

                  <span name="newWithdraw.item" class="text-red-600 text-sm" />
                </div>

                <!-- motivo -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="newWithdraw.reason"
                    v-model="store.newWithdraw.reason"
                    :required-field="true"
                    :info="'Motivo da retirada'"
                    label="Motivo da retirada"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.newWithdraw.reason = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="newWithdraw.reason" class="text-red-600 text-sm" />
                </div>
              </div>

              <!-- coleção de itens -->
              <div
                v-if="dataItems?.length"
                class="mb-2 w-full p-1"
              >

                <div class="w-full flex justify-between text-gray-400 pt-2">
                  <small class="w-full mt-auto mb-auto text-left font-medium">
                    ITENS SOLICITADOS:
                  </small>
                </div>

                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataItems"
                  :key="index"
                >
                  <ArrayItemsVue
                    :item="item"
                    @removeItem="removeItemByDataItems(index)"
                    @updateItem="updateItemInList"
                  />
                </div>
              </div>

              <!-- dados da retirada / resumo -->
              <div v-if="dataItems && dataItems?.length && store?.newWithdraw?.employee">
                <hr>

                <div class="w-full flex justify-between text-gray-400 pt-2">
                  <small class="w-full mt-auto mb-auto text-left font-medium">
                    RESUMO:
                  </small>
                </div>

                <!-- quantidade de itens -->
                <div class="w-full flex justify-between text-gray-400 pt-2">
                  <label class="w-full mt-auto mb-auto text-left font-medium">
                    ITENS SOLICITADOS
                  </label>

                  <label class="w-full font-semibold text-base">
                    <label class="w-full text-base text-black">
                      {{ dataItems?.length }}
                    </label>
                  </label>
                </div>

                <!-- funcionário -->
                <div class="w-full flex justify-between text-gray-400">
                  <label class="w-full mt-auto mb-auto text-left font-medium">
                    FUNCIONÁRIO:
                  </label>

                  <label class="w-full text-base text-black">
                    {{ store.newWithdraw.employee.name }}
                  </label>
                </div>

                <!-- função -->
                <div class="w-full flex justify-between text-gray-400">
                  <label class="w-full mt-auto mb-auto text-left font-medium">
                    FUNÇÃO:
                  </label>

                  <label class="w-full text-base text-black">
                    {{ selectedRole }}
                  </label>
                </div>

                <hr>
              </div>

            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <FloatBackPage />
  </div>
</template>

<script>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import CleanInputButton from './helpers/CleanInputButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import ArrayItemsVue from './helpers/ArrayItems.vue'
import { dataHome } from '../store/'

export default {
  name: 'FormVue',
  props: {
    descriptionTitle: String,
    employee: Array,
    stock: Array
  },
  components: {
    CleanInputButton,
    FloatBackPage,
    SimpleSelect,
    ArrayItemsVue,
    BaseInput
  },
  data () {
    const store = dataHome.state
    const homeStore = dataHome

    return {
      store,
      // dataItems,
      homeStore
    }
  },
  computed: {
    selectedRole () {
      const selectedEmployee = this.employee.find(
        (emp) => emp.name === this.store.newWithdraw.employee.name
      )
      return selectedEmployee ? selectedEmployee.role : 'Cargo não informado'
    },
    dataItems() {
      const selected = dataHome?.getters?.getValueItnsSelected;
      return selected?.length > 0 ? selected : [];
    },
  },
  methods: {
    mountItemsToSend (item) {
      const existingItem = dataHome.getters.getValueItnsSelected.find((i) => i.id === item.id)

      if (!existingItem) {
        // this.dataItems.push({ ...item, amount: 1 })
        dataHome.dispatch('setCollectValue', { ...item, amount: 1 });
      }
      
      // this.dataItems.push(item)
      this.store.newWithdraw.item = dataHome.getters.getValueItnsSelected
    },
    removeItemByDataItems (index) {
      dataHome.dispatch('removeItemByCollectValue', index);
      // this.dataItems.splice(index, 1)

    },
    updateItemInList (updatedItem) {
      // const itemIndex = this.dataItems.findIndex((item) => item.id === updatedItem.id)
      const itemIndex = dataHome.getters.getValueItnsSelected.findIndex((item) => item.id === updatedItem.id)
      if (itemIndex !== -1) {

        dataHome.getters.getValueItnsSelected[itemIndex] = updatedItem // Atualiza o item na lista
        this.store.newWithdraw.item = dataHome.getters.getValueItnsSelected // Atualiza o estado no Vuex
        // this.dataItems[itemIndex] = updatedItem // Atualiza o item na lista
        // this.store.newWithdraw.item = this.dataItems // Atualiza o estado no Vuex
      }
    }
  },
  mounted () {
    return (this.store.newWithdraw.company_id =
      localStorage.getItem('__company'))
  }
}
</script>
