<template>
  <div class="w-full p-1 block min-h-[75vh]">
    <div
      class="mb-1 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
      v-for="(employeeReports, employeeId) in groupedReports"
      :key="employeeId"
    >
      <p
        @click="toggleSubMenuOrder(employeeId)"
        class="border border-gray-300 rounded p-2 text-lg text-[#2c3e50] font-semibol flex text-left cursor-pointer"
      >
        <span class="w-full text-[#2c3e50] font-bold">
          {{ findNameById(employeeId) }}
        </span>
        <span class="text-end pr-3">
          <i
            class="fa-solid fa-chevron-up"
            :class="rotateIconOrderMenu(employeeId)"
          ></i>
        </span>
      </p>
      <div class="rounded">
        <Transition mode="out-in">
          <div
            v-if="isSubMenuOrderVisible(employeeId)"
            class="p-2 overflow-x-auto border border-gray-300"
          >
            <a
              class="text-blue-400 hover cursor-pointer"
              v-for="(report, index) in employeeReports"
              :key="index"
              @click="getReportById(report['id'])"
            >
              <div
                class="pt-2 pb-2 text-left border-b-2 border-gray-300 min-w-[50rem] overflow-x-auto"
              >
                <u>
                  <i>
                    Declaração de recebimento de EPI -
                    {{ findNameById(report["employee_id"]) }} -
                    {{ report["created_at"] }}.pdf
                    <i class="fa-solid fa-arrow-up-right-from-square ml-1"></i>
                  </i>
                </u>
              </div>
            </a>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { PdfCreate } from '@/utils/reports/generate-blob'
import { storeApp } from '@/store'

export default {
  props: {
    employees: Array,
    reports: Array
  },
  data () {
    return {
      reportUrl: process.env.VUE_APP_IMAGE_SERVE.concat('storage/'),
      processedEmployeeIds: []
    }
  },
  computed: {
    groupedReports () {
      const grouped = {}
      this.reports.forEach((report) => {
        if (!grouped[report.employee_id]) {
          grouped[report.employee_id] = []
        }
        grouped[report.employee_id].push(report)
      })
      return grouped
    }
  },
  methods: {
    async getReportById (id) {
      storeApp.state.loading = true

      const url = `${process.env.VUE_APP_API_URL}app/withdraw/reports/${id}`

      const report = new PdfCreate(url)
      return await report.fetchPdf().finally(() => {
        storeApp.state.loading = false
      })
    },
    findNameById (id) {
      const employee = this.employees.find((user) => user.id === Number(id))
      return employee ? employee.name : 'Nome não encontrado'
    },
    toggleSubMenuOrder (employeeId) {
      this.processedEmployeeIds[employeeId] =
        !this.isSubMenuOrderVisible(employeeId)
    },
    isSubMenuOrderVisible (employeeId) {
      return this.processedEmployeeIds[employeeId]
    },
    rotateIconOrderMenu (employeeId) {
      return this.isSubMenuOrderVisible(employeeId)
        ? 'rotate-icon-up'
        : 'rotate-icon-down'
    }
  }
}
</script>

<style scoped lang="scss">
.rotate-icon-up {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.rotate-icon-down {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.hover {
  :hover {
    color: purple;
    text-decoration: underline purple;
  }
}
</style>
