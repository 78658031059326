<template>
  <div class="profile-page">
    <section>
      <div class="container mx-auto">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-3"
        >
          <div class="px-1">
            <div class="flex flex-wrap justify-center">
              <div
                class="w-full px-4 lg:order-2 flex justify-center"
              >
                <img
                  style="width: 100px; height: 100px;"
                  :src="
                    props.employee.image ? imageServe + props.employee.image : 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg'"
                  class="shadow-xl rounded-full align-middle border-none max-w-150-px mb-3"
                />
              </div>
            </div>

            <div class="text-center">
              <h3
                class="text-2xl font-semibold leading-normal mb-1 text-blueGray-700"
              >
                {{ props.employee.name }}
              </h3>
              <div
                class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
              >
                <i
                  class="fa-solid fa-user-tie mr-2 text-lg text-blueGray-400"
                ></i>
                {{ props.employee.role }}
              </div>
            </div>

            <div class="mt-2 border-t border-blueGray-200 text-center">
              <div class="flex flex-wrap justify-center">
                <div class="w-full">
                  <div
                    class="md:text-start text-blueGray-600 mt-4 mb-4 overflow-y-auto max-h-60 scroll rounded p-1"
                  >
                    <table class="w-full">
                      <thead>
                        <tr>
                          <TableHeadField :text-center="false" class="rounded">
                            <span class="text-lg text-blueGray-700"> Treinamento </span>
                          </TableHeadField>
                          <TableHeadField :text-center="false" class="rounded">
                            <span class="text-lg text-blueGray-700"> Vencimento </span>
                          </TableHeadField>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in setTrainingsAndValidity()" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
                          <td class="text-left whitespace-nowrap text-gray-900 cursor-pointer min-w-[8px] pt-2 pb-2">
                            <div class="pl-4">
                              <span>
                                {{ row.name.substring(0, 25) + '...' }}
                              </span>
                            </div>
                          </td>
                          <td class="text-left whitespace-nowrap text-gray-900 cursor-pointer min-w-[8px] pt-2 pb-2">
                            <div class="text-center">
                              <span>
                                {{ row.period }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import TableHeadField from '@/components/Tables/TableHeadField.vue'
import moment from 'moment'
import { defineProps } from 'vue'

const props = defineProps({
  employee: {
    trainings: [Object, Array],
    name: String,
    function: String
  }
})
const imageServe = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

function setTrainingsAndValidity () {
  const dataTraining = props.employee

  const arr = []
  if (dataTraining.assigned) {
    dataTraining.assigned.forEach((element) => {
      arr.push({
        name: element.trainings.name,
        period: sumValidity(element.created_at, element.period.validity)
      })
    })
  }

  return arr
}

function sumValidity (createdDate, validity) {
  const format = 'DD/MM/YYYY'

  const newDate = moment(createdDate).add(Number(validity), 'days')

  return newDate.format(format)
}

</script>

<style lang="scss" scoped>
.text-align-block {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
}
.scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888ab;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>
