<template>
  <div class="ml-1 mt-2 p-2 border border-gray-200 rounded bg-gray-50 inline-block text-left">

    <small class="text-gray-400">
      <label class="font-medium">
        ITEM:
      </label>
      <label class="text-[#6fb171] text-base font-bold">
        {{ 
          item.name.length > 20 
          ? item.name.slice(0, 20).concat("...")
          : item.name
        }}
      </label>
    </small>

    <br v-if="item.size">
    <small v-if="item.size" class="text-gray-400">
      <label class="font-medium">
        TAMANHO:
      </label>
      <label class="text-[#6fb171] text-base font-bold">
        {{ 
          item.size.length > 10 
          ? item.size.slice(0, 20).concat("...")
          : item.size
        }}
      </label>
    </small>

    <br>

    <small class="text-gray-400 flex pt-2 mt-auto mb-auto">
      <!-- Quantidade: <label class="text-[#6fb171]"> {{ 'amount' }} </label> -->
      <label class="font-medium">
        QUANTIDADE:
      </label>

      <input
        type="number"
        value="1"
        v-model="item.amount"
        placeholder="1"
        min="1"
        class="text-center text-base ml-auto mr-auto mt-auto mb-auto font-bold border border-[#6fb171] rounded text-[#6fb171]"
        style="max-width: 45px;"
        @input="updateAmount"
      >
    </small>
  </div>

  <button
    class="flex items-center justify-center bg-red-500 text-white p-2 rounded-full -ml-4 -mt-1 h-5 w-5 "
    @click="removeItem"
  >
    <i class="fas fa-times"></i>
  </button>
</template>

<script>
export default {
  props: {
    item: Object
  },
  methods: {
    removeItem () {
      this.$emit('removeItem')
    },
    updateAmount () {
      this.$emit('updateItem', this.item) // Emite o item atualizado para o componente pai
    }
  },
  emits: ['removeItem', 'updateItem']
}
</script>
