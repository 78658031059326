<template>
  <div>
    <Form
      :description-title="'Atribua treinamentos para membros da equipe'"
      :employee="employeeData"
      :training="trainingData"
    >
      <CreateButton
        @click="assignTraining()"
        :icon="PhStudent"
        :label="'Registrar'"
      />
    </Form>
  </div>
</template>

<script setup>
import EmployeesService from '@/modules/employees/services/employees.service'
import CreateButton from '@/components/Buttons/Create.vue'
import TrainingService from '../service/training.service'
import Form from './../components/Form.vue'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataTraining } from '../store'
import { onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import * as yup from './../../../validator'
import {
  PhStudent
} from "@phosphor-icons/vue";
import employeesService from '@/modules/employees/services/employees.service'

const employeeData = ref([])
const trainingData = ref([])
const store = dataTraining.state
const appStore = storeApp.state
const schema = yup.object().shape({
  trainings: yup.array().required().label('Treinamento'),
  employee: yup.object().required().label('Funcionário'),
  periodicity: yup.number().required().label('Periodicidade'),
  carried_out: yup.string().label('Realização').nullable(),
  date_start: yup.string().label('Data inicial').nullable(),
  date_end: yup.string().label('Data final').nullable(),
  periods_in_day: yup.object().label('Periodos em dia').nullable(),
})

onMounted(async () => {
  appStore.loading = true

  dataTraining.commit('reset');

  await Promise.all([
    getEmployeesData(),
    getTrainingData()
  ])
  appStore.loading = false
})

const getEmployeesData = async () => {
  const dataEmployees = []

  // TODO: fazer apenas uma requisição na inicialização do component
  await EmployeesService.getAllEmployees()
    .then((res) => {
      res.data.forEach((element) => {
        dataEmployees.push({
          id: element.id,
          name: element.name
        })
      })
    })
    .catch((err) => {
      toast(
        'Aviso',
        'Funcionários não encontrados',
        'error'
      )
    })
  employeeData.value = dataEmployees
}

const getTrainingData = async () => {
  const dataTrainings = []
  await TrainingService.getAllTrainings()
    .then((res) => {
      res.data.forEach((element) => {
        dataTrainings.push({
          id: element.id,
          name: element.name,
          type: setType(element.type)
        })
      })
    })
    .catch((err) => {
      toast(
        'Aviso',
        'Treinamentos não encontrados',
        'error'
      )
    })

  trainingData.value = dataTrainings
}

function setType(type) {
  if(type == 'initial')
    return 'INICIAL';

  if(type == 'recicle')
    return 'RECICLAGEM';

  if(type == 'capacitation' || type == 'capacitaçao' )
    return 'CAPACITAÇÃO'; 

  if(type == 'multiplyer')
    return 'MULTIPLICADOR';
}

async function assignTraining () {
  appStore.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store))

  if (validate && validate.length > 0) {
    console.log(validate);
    
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  await TrainingService.assignTraining(store)
    .then((res) => {
      appStore.loading = false
      dataTraining.dispatch('cleanCollect');

      Promise.allSettled([  
        employeesService.getAllEmployees()
          .then(res => {
            dataTraining.dispatch('setEmployeeTrainings', res.data);
          }).catch(error => {
            console.log(error);
          })
      ]);

      dataTraining.commit('reset');

      if (res.data.success) {
        toast(
          'Sucesso',
          'Treinamento atribuído ao funcionário',
          'success'
        )
      }
    }).catch((err) => {
      appStore.loading = false

      toast(
        'Aviso',
        'Teinamento não atribuido ao funcionário',
        'error'
      )
    })
}
</script>
