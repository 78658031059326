<template>
  <div>
    <Form
      :description-title="'Registrar afastamento de funcionário'"
    >
      <CreateButton
        @click="registrarAfastamento()"
        :icon="PhCloudCheck"
        :label="'Registrar'"
      />
    </Form>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import Form from './../components/Afastamento.vue'
import AsosService from '../service/asos.service'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataAso } from './../store'
import { storeApp } from '@/store'
import { onMounted } from 'vue'
import * as yup from '@/validator'
import {
  PhCloudCheck
} from "@phosphor-icons/vue";

const appStore = storeApp.state
const store = dataAso.state

const schema = yup.object().shape({
  afastamento_form: yup.object().shape({
    employee: yup.object().required('Informe o funcionário').label('Funcionário'),
    type: yup.string().required('Informe o motivo').label('Motivo'),
    reason: yup.string().required('Informe o tipo do afastamento').label('Tipo'),
  })
})

async function registrarAfastamento () {
  appStore.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  // upload file
  if(store.afastamento_form.doc !== undefined) {
    store.afastamento_form.docName = localStorage.getItem('__company').concat('/docs/') + store.afastamento_form.docName
    
    Promise.allSettled([
      AsosService.uploadFile(store.afastamento_form.doc)
        .then(res => {
          toast(
            'Sucesso',
            'Seu documento foi salvo',
            'success'
          )
        }).catch(err => {
          toast(
            'Aviso',
            'Seu arquivo não foi salvo',
            'error'
          )
        })
    ])
  }

  delete store.afastamento_form.doc

  await AsosService.register(store.afastamento_form)
    .then(res => {
      if (res?.data?.success && res?.data?.data) {
        toast(
          'Sucesso',
          res?.data?.message,
          'success'
        )

        dataAso.dispatch('resetForm')
        dataAso.dispatch('setAllDocs', res?.data?.data)
      }

      appStore.loading = false
    }).catch(err => {
      appStore.loading = false

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      return toast(
        'Aviso',
        'Algo deu errado ao registrar documento',
        'error'
      )
    })
}

onMounted(async () => {
  appStore.loading = true
  dataAso.commit('reset');
  appStore.loading = false
})

</script>
