<template>
  <div class="profile-page">
    <section>
      <div class="container mx-auto">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-3"
        >
          <div class="px-1">
            <div class="flex flex-wrap justify-center">
              <div class="w-full px-4 lg:order-2 flex justify-center">
                <img
                  style="width: 100px; height: 100px"
                  :src="
                    props.item.image ? imageServe + props.item.image :
                    defaultImg
                  "
                  class="shadow-xl rounded-full align-middle border-none max-w-150-px mb-3"
                />
              </div>
            </div>

            <div class="text-center">
              <h3
                class="text-2xl font-semibold leading-normal mb-1 text-blueGray-700"
              >
                {{ props.item.name }}
              </h3>
              <section class="w-full flex justify-center">
                <div class="w-1/2 text-left">
                  <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i
                      class="fa-solid fa-cubes mr-2 text-lg text-blueGray-400"
                    ></i>
                    Quantidade: <span>{{ props.item.inventory }}</span>
                  </div>
                  <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i
                      class="fa-solid fa-certificate mr-2 text-lg text-blueGray-400"
                    ></i>
                    CA: <span>{{ props.item.CA }}</span>
                  </div>
                  <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i
                      :class="checkValidity(props.item.validity) ? 'fa-regular fa-calendar-check' : 'fa-regular fa-calendar-xmark'"
                      class=" mr-2 text-lg text-blueGray-400"
                    ></i>
                    Val. CA: {{ props.item.validity }}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import moment from 'moment'
import { defineProps } from 'vue'
import defaultImage from '@/assets/logo/protege.png'

const props = defineProps({
  item: [Object, Array]
})

const imageServe = process.env.VUE_APP_IMAGE_SERVE + 'storage/'
const defaultImg = defaultImage

function checkValidity (dateToCheck) {
  const today = moment()
  const comparisonDate = moment(dateToCheck, 'DD/MM/YYYY')

  return comparisonDate.isSameOrAfter(today)
}
</script>
