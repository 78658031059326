<template>
  <div>
    <PageTitleVue 
      :sub-title="'Gerencie os documentos dos seus funcionários'"
    > 
      ASOS
      <template #action>
        <div>
          <Create 
            v-if="store.getters.getActiveComponent.__name === 'AsosList'"
            :icon="PhCloudArrowUp"
            :label="'Novo upload'"
            :width-medium="true"
            @click="setFormComponentActive"
          />

          <Create 
            v-else
            :icon="PhArrowLeft"
            :label="'Ver documentos'"
            :width-medium="true"
            @click="setListComponentActive"
          />
        </div>
      </template>
    </PageTitleVue>
    
    <!-- <ContainerVue> -->
      <AsosTabs>

        <template #employee>
          <ListAndForm />
        </template>

        <template #form>
          <Afastamento />
        </template>

      </AsosTabs>
    <!-- </ContainerVue> -->
  </div>
</template>

<script setup>
import AsosTabs from './components/_partials/AsosTabs.vue'
import Afastamento from './layout/AfastamentoForm.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import AsosService from './service/asos.service'
import Create from '@/components/Buttons/Create.vue'
// import TrainingsList from './layout/TrainingsList.vue'
import ListAndForm from './layout/BaseListAndFormAso.vue'
import ContainerVue from '@/components/Container.vue'
import { onBeforeMount } from 'vue'
import { storeApp } from '@/store'
import { dataAso } from './store'
import { PhArrowLeft, PhCloudArrowUp } from '@phosphor-icons/vue'
import { markRaw } from 'vue'
import UploadForm from './layout/pages/UploadForm.vue'

const store = dataAso

onBeforeMount(async () => {
  storeApp.state.loading = true
  await getDocs()
  Promise.allSettled([
    await getEmployeesByCombo(),
    await getAfastamentoCombo()
  ])
  storeApp.state.loading = false
})

// pega todos os asos
async function getDocs() {
  await AsosService.getAllDocs()
  .then(res => {
    console.log(res.data);
    store.dispatch('setAllDocs', res.data)
  }).catch(err => {
    console.log(err);
  })
}

// pega os nomes dos colaboradores para o combo do form
async function getEmployeesByCombo() {
  await AsosService.getEmployeesByCombo()
  .then(res => {
    store.dispatch('setEmployees', res.data)
  }).catch(err => {
    console.log(err);
  })
}

// combo dos afastamentos
async function getAfastamentoCombo() {
  await AsosService.getAfastamentoCombo()
  .then(res => {
    store.dispatch('setAfastamento', res.data)
  }).catch(err => {
    console.log(err);
  })
}

// altera o component ativo
function setFormComponentActive() {
  dataAso.dispatch('setFormComponentActive')
}

// altera o component ativo
function setListComponentActive() {
  dataAso.dispatch('setListComponentActive')
}

</script>
