<template>
  <div class="bg-grid">
    <PageTitleVue> Inicio </PageTitleVue>

    <!-- <Container class="bg-grid"> -->
      <main class="min-h-[85vh] flex flex-col items-center">
        <!-- Logo -->
        <div class="mt-2 w-full">
          <Titles 
            class="uppercase font-semibold"
            :w25="true" 
            :left="false" 
            :label="date" 
          />

          <div>
            <img
              :src="companyLogo"
              alt="Logo"
              class="w-64 h-auto sm:w-54 mx-auto object-contain mt-1 mb-1 rounded-full"
            />
          </div>
        </div>

        <!-- Ações -->
        <div class="w-full text-left">
          <Titles label="Gerencie sua empresa com velocidade">
            Ações rápidas
          </Titles>
        </div>
        <section
          class="mt-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 w-full px-6 max-w-5xl justify-center"
        >

          <!-- EPI's -->
          <CardButton
            @click="navigateTo('new-request')"
            :icon="PhHandCoins"
            :title="'entrega de'"
            :label="'EPIs'"
            :animate="false"
          />
          
          <!-- funcionários -->
          <CardButton
            @click="navigateTo('employees')"
            :icon="UserGroupIcon"
            :title="'gerenciar'"
            :label="'Funcionarios'"
            :animate="false"
          />

          <!-- itens -->
          <CardButton
            @click="navigateTo('stock')"
            :icon="PhPackage"
            :title="'gerenciar'"
            :label="'Estoque'"
            :animate="false"
          />

          <!-- treinamentos -->
          <CardButton
            @click="navigateTo('training')"
            :icon="PhStudent"
            :title="'gerenciar'"
            :label="'Treinamentos'"
            :animate="false"
          />
          
          <!-- treinamentos -->
          <CardButton
            @click="navigateTo('asos')"
            :icon="PhStethoscope"
            :title="'gerenciar'"
            :label="'ASOS'"
            :animate="false"
          />
          
          <!-- treinamentos -->
          <CardButton
            @click="navigateTo('company')"
            :icon="BuildingOfficeIcon"
            :title="'gerenciar dados da'"
            :label="'Empresa'"
            :animate="false"
          />

          

        </section>
      </main>
    <!-- </Container> -->
  </div>
</template>

<script setup>
import PageTitleVue from '@/components/Titles/PageTitle.vue';
import CardButton from '@/components/Buttons/CardButton.vue';
import Container from '@/components/Container.vue';
import Titles from './components/Titles.vue';
import { ref, computed } from 'vue';
import { 
  PhPackage,
  PhStethoscope,
  PhStudent,
  PhHandCoins
} from '@phosphor-icons/vue';
import {
  UserGroupIcon,
  BuildingOfficeIcon
} from '@heroicons/vue/24/outline'
import router from '@/router';

// Computed para obter a logo
const companyLogo = computed(() => {
  const storedLogo = localStorage.getItem('__company_logo');
  return storedLogo ? 
  ref(process.env.VUE_APP_IMAGE_SERVE.concat('storage/')).value + storedLogo : 
  require('@/assets/logo/protege-old.png');
});

const today = new Date();
const dayNames = [
  "domingo", 
  "segunda-feira", 
  "terça-feira", 
  "quarta-feira", 
  "quinta-feira", 
  "sexta-feira", 
  "sábado"
];
const monthNames = [
  "janeiro", 
  "fevereiro", 
  "março", 
  "abril", 
  "maio", 
  "junho", 
  "julho", 
  "agosto", 
  "setembro", 
  "outubro", 
  "novembro", 
  "dezembro"
];

const date = `${dayNames[today.getDay()]}, ${today.getDate()} de ${monthNames[today.getMonth()]} de ${today.getFullYear()}`;

// Função para navegação
function navigateTo(route) {
  router.push({ name: route });
}
</script>

<style>
/* From Uiverse.io by kandalgaonkarshubham */ 
.bg-grid {
  width: 100%;
  height: 100%;
  --s: 27px; /* control the size */
  --c: #0000, #28282803 0.5deg 119.5deg, #0000 120deg;
  --g1: conic-gradient(from 60deg at 56.25% calc(425% / 6), var(--c));
  --g2: conic-gradient(from 180deg at 43.75% calc(425% / 6), var(--c));
  --g3: conic-gradient(from -60deg at 50% calc(175% / 12), var(--c));
  background: var(--g1), var(--g1) var(--s) calc(1.73 * var(--s)), var(--g2),
    var(--g2) var(--s) calc(1.73 * var(--s)), var(--g3) var(--s) 0,
    var(--g3) 0 calc(1.73 * var(--s)) #1e1e1e00;
  background-size: calc(2 * var(--s)) calc(3.46 * var(--s));
}

</style>
