<!-- BaseModal.vue -->
<template>
  <Transition mode="out-in">
    <div
      v-if="isOpen"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      @click.self="closeModal"
    >
      <div
        class="rounded-lg shadow-lg w-full max-w-md relative max-h-[80vh] overflow-hidden"
        :class="[
          props.customClass.dark ? 'bg-[#2b3038]' : 'bg-white'
        ]"
      >
        <button
          @click="closeModal"
          class="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <XIcon class="h-6 w-6" />
        </button>
          <slot />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { 
  PhX as XIcon
} from '@phosphor-icons/vue'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  customClass: { Object, default: false }
})

const emit = defineEmits(['update:isOpen'])

function closeModal () {
  emit('update:isOpen', false)
}
</script>

<style scoped>
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
