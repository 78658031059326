<template>
  <div>
    <PageTitleVue> 
      Cadastro de Funcionário 
    </PageTitleVue>
    <!-- <ContainerVue> -->
      <Form :descriptionTitle="'Dados pessoais'">

        <ShiftForm
          :descriptionTitle="'Informações complementares'"
          :descriptionSubTitle="''"
        />

        <div class="w-full flex justify-center">
          <CreateButton
            :key="buttonLabel"
            @click="addEmployee"
            :icon="PhUserCirclePlus"
            :label="buttonLabel"
          />
        </div>
      </Form>
    <!-- </ContainerVue> -->
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import EmployeesService from './../services/employees.service'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ContainerVue from '@/components/Container.vue'
import Form from '../layout/Form.vue'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataEmployee } from '../store'
import { onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import * as yup from './../../../validator'
import ShiftForm from './ShiftForm.vue'
import { PhUserCirclePlus } from '@phosphor-icons/vue'

const appStore = storeApp.state
const store = dataEmployee

const confirmUpdateEmployeeData = ref('false')
const buttonLabel = ref('Cadastrar')
const errors = ref([])

// variavel definida para ver se o valor de CPF (vampo de validação) sofreu alteração
const oldValueField = ref('')

const schema = yup.object().shape({
  employee: yup.object().shape({
    name: yup.string().required().label('Nome').min(5),
    function: yup.string().required().label('Função'),
    registeredSince: yup.string().nullable(),
    phone: yup.string().required().label('Celular'),
    document: yup.object().shape({
      cpf: yup.string().label('CPF').nullable().required(),
      cnh: yup.string().label('CNH').nullable().max(6)
    }),
    address: yup.object().shape({
      city: yup.string().label('Cidade').required().min(1),
      country: yup.string().label('Bairro').required().min(1)
    })
  })
})

onMounted(() => {
  store.commit('reset')
})

async function addEmployee () {
  appStore.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store.state))

  if (validate && validate.length > 0) {
    // console.log(validate);
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  // fluxo da imagem
  if (
    store.state.employee.photo &&
    typeof store.state.employee.photo === 'object' &&
    typeof store.state.employee.imgPath === 'string' &&
    store.state.employee.imgPath !== '[]'
  ) {
    await EmployeesService.editImage(
      store.state.employee.photo,
      store.state.employee.imgPath
    )
      .then((res) => {
        store.state.employee.imgPath = res.data
        store.state.employee.photo = res.data
      })
      .catch((err) =>
        toast(
          'Aviso',
          'Upload de novo arquivo não concluído',
          'error'
        )
      )
  } else if (
    store.state.employee.photo &&
    typeof store.state.employee.photo !== 'string'
  ) {
    await EmployeesService.saveImage(store.state.employee.photo)
      .then((res) => {
        store.state.employee.imgPath = res.data
        store.state.employee.photo = res.data
      })
      .catch((err) =>
        toast(
          'Aviso',
          'Upload de arquivo não concluído',
          'error'
        )
      )
  } else {
    store.state.employee.photo = store.state.employee.imgPath
  }

  /**
   * Foi criado este fluxo pois, o cliente estava recebendo a informação de que o iten já existia
   * porém quando ele recebia essa informação e alterava o valor de cpf, para um que também existia,
   * essa notificação não era informada, e ele apenas alterava o valor de outro cpf sem avisar
   */
  if (oldValueField.value == store.state.employee.document.cpf) {
    confirmUpdateEmployeeData.value = 'true'
  } else {
    confirmUpdateEmployeeData.value = 'false'
  }

  store.state.employee.company_id = appStore.accessToken.getItem('__company')

  await EmployeesService.register(store.state.employee, confirmUpdateEmployeeData.value)
    .then((res) => {
      appStore.loading = false

      if (res.data.info) {
        oldValueField.value = store.state.employee.document.cpf
        // define os campos para envio e atualiza o botão
        confirmUpdateEmployeeData.value = 'true'
        buttonLabel.value = 'Continuar'

        return toast(
          'Protege informa: ',
          res.data.message,
          'info'
        )
      }

      if (res.data.success) {
        toast(
          'Sucesso',
          res.data.message,
          'success'
        )
      }

      if (errors.value.length > 0) {
        errors.value.pop()
      }
      oldValueField.value = ''
      confirmUpdateEmployeeData.value = 'false'
      buttonLabel.value = 'Cadastrar'
      store.commit('reset')
    }).catch((err) => {
      appStore.loading = false
      // console.log(err)

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      return toast(
        'Aviso',
        'Algo deu errado ao registrar funcionário',
        'error'
      )
    })
}
</script>
