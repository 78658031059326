<template>
   <div
    @click="$emit('open-modal')"
    class="cursor-pointer agendamento-card p-1 rounded-lg shadow-md text-white hover:shadow-lg hover:-translate-y-1 transition-all"
  >
    <!-- header -->
    <div class="block text-[#2f2f2f]  w-full">
      
      <!-- foto -->
      <div class="flex items-center space-x-2 mb-1 p-2 rounded-md w-full ">
        
        <!-- <CheckIcon class="w-5 h-5 text-3xl" /> -->

        <div class="rounded-full border" style="width: 80px;">
          <img 
            v-if="employee?.image" 
            class="rounded-full"
            style="width: 70px; height: 60px;"
            :src="imageServe + employee?.image" 
            alt="Funcionário" 
          >
  
          <img 
            v-else 
            class="rounded-full"
            style="width: 60px; height: 60px;"
            src="https://protege-app.ad.app.br/protege.png" 
            alt="Protege"
          >
        </div>

        <div class="flex w-full">

          <!-- nome -->
          <span class="text-lg text-center font-semibold mt-auto mb-auto  w-full">
            {{
              employee?.name?.length > 15 ?
                employee?.name.slice(0, 15).concat("...") : 
                employee?.name
            }}
          </span>
          
          <!-- botões de ação -->
          <div class=" ml-auto">
            <a
              class="w-full flex justify-center p-1 rounded-t-lg z-10"
              title="Lançar serviço"
            >
              <PhGearSix class="w-8 h-8" />
              <!-- <svg
                width="36"
                height="36"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5995 45.0848C34.6117 45.0848 44.3495 35.347 44.3495 23.3348C44.3495 11.3226 34.6117 1.58484 22.5995 1.58484C10.5873 1.58484 0.849457 11.3226 0.849457 23.3348C0.849457 35.347 10.5873 45.0848 22.5995 45.0848ZM22.5995 45.8348C35.0259 45.8348 45.0995 35.7612 45.0995 23.3348C45.0995 10.9084 35.0259 0.834839 22.5995 0.834839C10.173 0.834839 0.0994568 10.9084 0.0994568 23.3348C0.0994568 35.7612 10.173 45.8348 22.5995 45.8348Z"
                  fill="#22c55e"
                />
                <path
                  d="M22.5995 14.1682V32.5015M26.7661 17.5015H20.5161C19.7426 17.5015 19.0007 17.8088 18.4537 18.3558C17.9067 18.9027 17.5995 19.6446 17.5995 20.4182C17.5995 21.1917 17.9067 21.9336 18.4537 22.4805C19.0007 23.0275 19.7426 23.3348 20.5161 23.3348H24.6828C25.4563 23.3348 26.1982 23.6421 26.7452 24.1891C27.2922 24.7361 27.5995 25.4779 27.5995 26.2515C27.5995 27.025 27.2922 27.7669 26.7452 28.3139C26.1982 28.8609 25.4563 29.1682 24.6828 29.1682H17.5995"
                  stroke="#22c55e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  transform="scale(1.5) translate(-7.5, -8)"
                />
              </svg> -->
            </a>
            <!-- <a
              @click="$emit('remove', props.id)"
              class="w-full flex justify-center p-1 rounded-t-lg mt-auto mb-auto z-10"
              title="Remover agendamento"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5995 45.0848C34.6117 45.0848 44.3495 35.347 44.3495 23.3348C44.3495 11.3226 34.6117 1.58484 22.5995 1.58484C10.5873 1.58484 0.849457 11.3226 0.849457 23.3348C0.849457 35.347 10.5873 45.0848 22.5995 45.0848ZM22.5995 45.8348C35.0259 45.8348 45.0995 35.7612 45.0995 23.3348C45.0995 10.9084 35.0259 0.834839 22.5995 0.834839C10.173 0.834839 0.0994568 10.9084 0.0994568 23.3348C0.0994568 35.7612 10.173 45.8348 22.5995 45.8348Z"
                  fill="red"
                />
                <path
                  d="M13 13L33 33M33 13L13 33"
                  stroke="red"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a> -->
          </div>

        </div>

      </div>
    </div>

    <hr>

    <!-- body -->
    <div class="justify-between items-end">

      <!-- celular -->
      <span class="pl-1 text-sm text-gray-400 text-left">
        <!-- <span class="text-lg font-semibold text-gray-400"> -->
        <small>
          celular: 
        </small>
        {{
          employee?.phone
        }}
      </span>

      <br>
      <!-- </div> -->

      <!-- função -->
      <span class="pl-1 text-sm text-gray-400 text-left">
        <small>
          função: 
        </small>
        {{ 
          employee?.role?.length > 20 ? 
          employee?.role.slice(0, 20).concat("...") : 
          employee?.role 
        }}
      </span>

      <br>

      <!-- treinamentos -->
      <span class="pl-1 text-sm text-gray-400 text-left">
        <small>
          Total de treinamentos: 
        </small>
        {{ 
          employee?.assigned?.length ?? 0
        }}
      </span>

    </div>

  </div>
</template>

<script setup>
import {
  PhClockAfternoon as ClockIcon,
  PhCheckCircle as CheckIcon,
  PhGearSix
} from '@phosphor-icons/vue'
import { defineProps } from "vue";

const imageServe = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  employee: {
    type: [Array, Object],
    required: true,
  }
});
</script>
