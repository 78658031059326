<template>
  <section
    :key="dataTraining.getters.getEmployeeTrainings"
  >
    <!-- search -->
    <form class="max-w-lg mx-auto mb-3 -mt-2">
      <div class="flex">
        
        <!-- drop button -->
        <button
          id="dropdown-button"
          data-dropdown-toggle="dropdown"
          class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
          type="button"
        >
          <!-- All categories -->
          Funções
          <svg
            class="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
  
        <!-- drop options -->
        <div
          id="dropdown"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-[40vh] overflow-y-auto"
            aria-labelledby="dropdown-button"
          >
            <li 
              v-for="(item, index) in uniqueRoles" :key="index"
            >
              <button
                type="button"
                class="inline-flex w-full px-4 py-2 hover:bg-gray-200 text-gray-700"
              >
                {{ item }}
              </button>
            </li>
  
          </ul>
        </div>
  
        <!-- input e button -->
        <div class="relative w-full">
          
          <!-- campo -->
          <input
            type="search"
            id="search-dropdown"
            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-[#0b7037] focus:border-[#0b7037] active:border-none"
            placeholder="Busque pelo funcionário"
            required
            disabled
          />
  
          <!-- send -->
          <button
            type="submit"
            class="cursor-not-allowed absolute top-0 end-0 px-2 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            disabled
          >
            <PhUserFocus class="w-7 h-7 mt-auto mb-auto" />
          </button>
  
        </div>
  
      </div>
    </form>
  
    <!-- cards -->
    <section :key="modalData">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-0"
        :key="clients"
      >
        <ClientCard
          v-for="(item, index) in clients"
          :key="index"
          :employee="item"
          :id="item.id"
          @open-modal="openModal(item)"
        />
      </div>
    </section>
  
    <!-- drawer -->
    <Transition mode="out-in">
      <BaseModal 
        :isOpen="isModalOpen" 
        @update:isOpen="closeModal"
        :key="modalData"
      >
        <!-- drawer employee -->
        <div
          id="drawer-right-example"
          class="fixed top-0 right-0 z-40 h-screen overflow-y-auto transition-transform bg-white w-80 overflow-x-hidden"
          :class="{
            'translate-x-0': isModalOpen,
            'translate-x-full': !isModalOpen,
          }"
          tabindex="-1"
          aria-labelledby="drawer-right-label"
        >
          <section>
            <!-- header -->
            <div class="bg-gray-100 p-2 pt-0 pb-0">
              <!-- TItulo -->
              <h5
                id="drawer-right-label"
                class="inline-flex items-center mb-4 text-lg font-semibold text-gray-600 dark:text-gray-600 mt-3 mr-6"
              >
                <PhInfo class="w-6 h-6 me-2.5 text-gray-700" />
                Treinamentos do funcionário
              </h5>
    
              <!-- close button -->
              <button
                type="button"
                aria-controls="drawer-right-example"
                class="text-gray-400 bg-transparent hover:text-gray-700 text-sm w-8 h-8 absolute top-1 -end-2"
                @click="closeModal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close menu</span>
              </button>
            </div>
    
            <!-- content -->
            <section class="p-2">
              <!-- dados -->
              <div class="pt-0 pb-0 mb-4">
                <!-- imagens -->
                <div class="p-2 pt-0 pb-0 items-center">
                  <!-- funcionário -->
                  <img
                    v-if="modalData?.image"
                    class="rounded-full ml-auto mr-auto border"
                    style="width: 100px; height: 100px"
                    :src="imageServe + modalData?.image"
                    alt="Funcionário"
                  />
                  <!-- default -->
                  <img
                    v-else
                    class="rounded-full ml-auto mr-auto border"
                    style="width: 100px; height: 100px"
                    src="https://protege-app.ad.app.br/protege.png"
                    alt="Protege"
                  />
                </div>
    
                <!-- dados -->
                <div class="flex text-sm pt-2 w-full justify-between font-semibold">
                  <!-- nome -->
                  <div class="w-full">
                    <label class="text-xs font-medium text-gray-400">Nome</label
                    ><br />
                    <label class="text-gray-700 text-sm uppercase">
                      {{
                        modalData?.name?.length > 20
                          ? modalData?.name.slice(0, 20).concat("...")
                          : modalData?.name
                      }}
                    </label>
                  </div>
    
                  <!-- função -->
                  <div class="w-full">
                    <label class="text-xs font-medium text-gray-400">Função</label
                    ><br />
                    <label class="text-gray-700 text-sm uppercase">
                      {{
                        modalData?.role?.length > 10
                          ? modalData?.role.slice(0, 10).concat("...")
                          : modalData?.role
                      }}
                    </label>
                  </div>
                </div>
    
                <!-- dados -->
                <div
                  class="flex text-gray-700 pt-2 w-full justify-between font-semibold"
                >
                  <!-- contato -->
                  <div class="w-full">
                    <label class="font-medium text-gray-400 text-xs">Contato</label
                    ><br />
                    <label class="text-gray-700 text-sm uppercase">
                      {{
                        modalData?.phone?.length > 20
                          ? modalData?.phone.slice(0, 20).concat("...")
                          : modalData?.phone
                      }}
                    </label>
                  </div>
    
                  <!-- CNH -->
                  <div class="w-full">
                    <label class="text-xs font-medium text-gray-400">CNH</label
                    ><br />
                    <label class="text-gray-700 text-sm uppercase">
                      {{
                        modalData?.cnh?.length > 20
                          ? modalData?.cnh.slice(0, 20).concat("...")
                          : modalData?.cnh
                      }}
                    </label>
                  </div>
                </div>
              </div>
    
              <hr />
    
              <!-- table -->
              <div class="overflow-x-hidden mt-4 max-h-[50vh] overflow-y-auto">
                <!-- titulo -->
                <div class="w-full">
                  <section
                    class="text-lg w-full p-5 pt-0 pb-1 px-1 font-semibold text-left text-gray-900 bg-white dark:text-gray-600"
                  >
                    Treinamentos
                    <p
                      class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400"
                    >
                      Gerencie todos os treinamnetos feitos por esse funcionário
                    </p>
                  </section>
                </div>
    
                <!-- lista -->
                <table
                  v-if="modalData?.assigned.length > 0"
                  class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                  <thead class="uppercase text-gray-900 border-b">
                    <tr>
                      <th
                        scope="col"
                        class="text-xs font-normal text-gray-400 px-1 py-1"
                      >
                        Nome
                      </th>
                      <th
                        scope="col"
                        class="text-xs font-normal text-gray-400 px-1 py-1 text-center"
                      >
                        Remover
                      </th>
                    </tr>
                  </thead>
                  
                  <tbody class="h-[4vh] overflow-y-auto bg-blue-400">
                    <tr
                      class="bg-white border-b dark:border-gray-300 px-2"
                      v-for="(item, index) in modalData?.assigned"
                      :key="index"
                    >
                      <!-- nome -->
                      <th
                        scope="row"
                        class="px-1 py-2 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {{
                          item.trainings.name.length > 20
                            ? item.trainings.name.slice(0, 20).concat("...")
                            : item.trainings.name
                        }}
                      </th>
    
                      <!-- delete -->
                      <th
                        scope="row"
                        class="px-1 py-2 ml-auto mr-auto text-gray-900 text-center cursor-pointer"
                        title="Remover treinamento"
                        @click="removeTrainingByEmployee(item?.id)"
                      >
                        <PhTrash class="h-6 w-full text-red-700" />
                      </th>
                    </tr>
                  </tbody>
                </table>
    
                <!-- sem dados -->
                <div v-else>
                  <NoDataAnim :width="250" :height="250" />
                </div>
              </div>
            </section>
    
            <!-- badge -->
            <div class="mt-auto bottom-0 w-full p-2 justify-items-center">
              <CreateButton
                :widthMedium="true"
                :label="'Gerar crachá'"
                :icon="PhIdentificationCard"
                @click="generateBadges(modalData?.id)"
              />
            </div>
          </section>
        </div>
      </BaseModal>
    </Transition>
  </section>
</template>

<script setup>
import ClientCard from "../components/_partials/ClientCard.vue";
import NoDataAnim from "@/components/Lotties/NoDataAnim.vue";
import BaseModal from "@/components/Modals/BaseModal.vue";
import CreateButton from "@/components/Buttons/Create.vue";
import employeesService, { PATH } from "@/modules/employees/services/employees.service";
import { PdfCreate } from "@/utils/reports/generate-blob";
import { computed, onMounted } from "vue";
import { dataTraining } from "../store";
import { initFlowbite } from "flowbite";
import { storeApp } from "@/store";
import { 
  PhTrash, 
  PhInfo, 
  PhIdentificationCard,
  PhUserFocus
} from "@phosphor-icons/vue";
import TrainingService from "../service/training.service";
import { showToast } from "@/helpers/UseToasts";

onMounted(() => {
  initFlowbite();
});

const imageServe = process.env.VUE_APP_IMAGE_SERVE + "storage/";
const store = dataTraining;

const isModalOpen = computed(() => store.getters.getModalEmployeesState);
const uniqueRoles = computed(() => store.getters.setRolesByFilter);
const modalData = computed(() => store.getters.getModalData);
const clients = computed(() => {
  const rawEmployees = store.getters.getEmployeeTrainings;
  return mergeDuplicateEmployees(rawEmployees);
});

async function generateBadges(id) {
  storeApp.state.loading = true;

  const url = `${process.env.VUE_APP_API_URL}${PATH}${id}/badges`;
  const report = new PdfCreate(url);

  return await report.fetchPdf().finally(() => {
    storeApp.state.loading = false;
  });
}

function mergeDuplicateEmployees(clients) {
  const groupedClients = {};
  const rolesSet = new Set(); // Armazena as funções únicas (role)

  clients.forEach((client) => {
    const key = client.name; // Agrupar pelo nome

    rolesSet.add(client.role);

    if (!groupedClients[key]) {
      groupedClients[key] = { ...client, assigned: [...client.assigned] };
    } else {
      // Junta os treinamentos que ainda não existem
      client.assigned.forEach((training) => {
        const exists = groupedClients[key].assigned.some(
          (assigned) => assigned.trainings.id === training.trainings.id
        );
        if (!exists) {
          groupedClients[key].assigned.push(training);
        }
      });
    }
  });

  store.dispatch(
    'setRolesByFilter', 
    Array.from(rolesSet)
  );

  return Object.values(groupedClients);
}

async function removeTrainingByEmployee($id) {
  storeApp.state.loading = true;

  await TrainingService.removeTrainingByEmployee($id)
    .then(res => {

      Promise.allSettled([  
        employeesService.getAllEmployees()
          .then(res => {
            dataTraining.dispatch('setEmployeeTrainings', res.data);
          }).catch(error => {
            console.log(error);
          })
      ]);

      if(res.data.success) {
        dataTraining.dispatch("setModalData", null);
        dataTraining.dispatch("setModalData", res.data.data);
        storeApp.state.loading = false;

        return showToast(
          'Sucesso',
          res.data.message,
          'success'
        )
      }
    }).catch(err => {
      storeApp.state.loading = false;

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return showToast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      return showToast(
        'Aviso',
        'Algo deu errado ao remover treinamento',
        'error'
      )
    })
}

function openModal(val) {
  dataTraining.dispatch("enableModalEmployees");
  dataTraining.dispatch("setModalData", val);
}

function closeModal() {
  dataTraining.dispatch("disableModalEmployees");
  dataTraining.dispatch("setModalData", null);
}
</script>

<style scoped lang="scss">  
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
