<template>
  <div class="p-0 md:p-2 flex flex-col items-center justify-start min-h-[75vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left block mb-4">
              <p class="mb-2 font-medium text-lg text-gray-900">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                <!-- funcionário -->
                <div class="md:col-span-5">
                  <div class="md:col-span-2 mt-1.5">
                    <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="form.employee">
                      Funcionário <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 flex rounded items-center"
                    >
                      <SimpleSelect
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        :data="dataAso.getters.getEmployees"
                        @selected-data="(val) => (store.form.employee = val)"
                      />
                    </div>
                  </div>

                  <span
                    name="form.employee"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- doc type -->
                <div class="md:col-span-5">
                  <div class="md:col-span-2 mt-1.5">
                    <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="form.type">
                      Tipo do documento <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 flex rounded items-center"
                    >
                      <SimpleSelect
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        :data="docType"
                        @selected-data="(val) => (store.form.type = val.name)"
                      />
                    </div>
                  </div>

                  <span
                    name="form.type"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- data de realizado -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="date"
                    name="form.realizado_em"
                    v-model="store.form.realizado_em"
                    label="Data da realização"
                    :info="'Data da realização'"
                    :required-field="false"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.form.realizado_em = null"
                      />
                    </template>
                  </BaseInput>
                </div>

                 <!-- data de vencimento -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="date"
                    name="form.expired_at"
                    v-model="store.form.expired_at"
                    label="Data do vencimento"
                    :info="'Data do vencimento'"
                    :required-field="true"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.form.expired_at = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="form.expired_at" class="text-red-600 text-sm" />
                </div>

                <!-- fluxo do documento -->
                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="EPI"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 mt-2 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[8rem] h-10 rounded-l-md rounded-r-none"
                  >
                    <!-- <i class="fa-solid fa-helmet-safety"></i> -->

                    <div class="flex mt-auto mb-auto h-full">
                      <div class="w-full mt-auto mb-auto flex justify-center">
                        <component 
                          :is="selectedFileName ? PhCloudArrowUp : PhFileArrowUp"
                          class="w-5 h-5 mr-1 text-gray-500 " 
                        />
    
                        Documento
                      </div>
                    </div>
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                  
                </div>
              </div>
              <span name="form.docName" class="text-red-600 text-sm w-full" />

            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <!-- <FloatBackPage /> -->
  </div>
</template>

<script>
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import CleanInputButton from '../utils/CleanInputButton.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import { dataAso } from '../store'
import { docType } from '../lookups/form';
import { 
  PhCloudArrowUp,
  PhFileArrowUp
} from '@phosphor-icons/vue';

export default({
  components: {
    SimpleSelect,
    CleanInputButton,
    BaseInput,
  },
  props: {
    descriptionTitle: String,
  },
  data () {
    return {
      PhFileArrowUp,
      PhCloudArrowUp,
      selectedFileName: undefined,
      selectedFileUrl: undefined,
      store: dataAso.state,
      dataAso,
      docType,
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        this.selectedFileName = file.name
        this.selectedFileUrl = URL.createObjectURL(file)
        
        this.store.form.docName = this.selectedFileName
        this.store.form.doc = file
      }
    }
  }
})
</script>
