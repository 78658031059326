<template>
  <div class="button-container">
    <button 
      class="brutalist-button openai"
      :class="{ 'animate-rotate': animate }"
      :title="label"
    >
      <!-- icons -->

      <div class="w-full h-full">
        <div class="openai-logo">
          <component 
            :is="icon" 
            class="h-5 w-5 openai-icon" 
          />
        </div>
        <div class="info h-full">
          <p class="h-full bottom-full mt-[4.5rem]">
            {{ label }}
          </p>
        </div>
      </div>

      <!-- labels -->
      <div class="button-text">
        <span>
          {{ title }}
        </span>
        
        <span>
          {{ label }}
        </span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "CardButton",
  props: {
    icon: {
      String,
      default: "fas fa-plus",
    },
    label: {
      String,
      default: "Adicionar",
      required: true,
    },
    title: {
      String,
      default: "Adicionar",
      required: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/* From Uiverse.io by 0xnihilism */ 
.button-container {
  display: flex;
  justify-content: center;
  gap: 16px; /* Adjust this value to increase or decrease space between buttons */
  padding: 10px;
}

.brutalist-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #358956;
  border-radius: 12px;
  padding: 0 5px;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  position: relative;
  // box-shadow: 3px 3px 10px #00000056;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 100px;
  width: 290px;
  cursor: pointer;
}

.brutalist-button.openai {
  background-color: #ffffff;
}

.brutalist-button::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -150%;
  width: 300%;
  height: 300%;
  border-radius: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.brutalist-button.openai::before {
  background-color: #358956;
}

.brutalist-button.claude::before {
  background-color: #fff;
}

.brutalist-button:hover::before {
  transform: translateX(-50%) scale(1);
}

.brutalist-button:hover {
  transform: translate(-4px, -4px);
  box-shadow: 4px 4px 5px #0000009b;
  border: 2px solid #000;
}

.brutalist-button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px #0000009b;
  border: 2px solid #000;
}

.openai-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.openai-logo {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.414); /* OpenAI's green color */
  border-radius: 50%; /* This creates the circular background */
}

.openai-icon {
  width: 40px;
  height: 40px;
  color: #000;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.brutalist-button:hover .openai-logo {
  animation: spin 5s linear infinite;
  width: 50px;
  height: 50px;
  top: 28%;
  left: 50%;

  .info {
    left: 100%;
  }
}

.brutalist-button:hover .openai-icon {
  transform: scale(0.6);
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  color: #fff;
}

.button-text span:first-child {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
}

.button-text span:last-child {
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  // color: #ffffff;
}

.brutalist-button:hover .button-text {
  opacity: 1;
  transform: translateY(0);
}

.brutalist-button.openai:hover .button-text {
  color: #fff;
}

.brutalist-button.openai:hover .button-text span:last-child {
  color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .brutalist-button:hover {
    transform: translate(-4px, -4px);
    box-shadow: 4px 4px 5px #0000009b;
  }
}

</style>
