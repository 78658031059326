import { createStore } from 'vuex'

export const dataHome = createStore({
  getters: {
    itensSelected: (state) => state.newWithdraw.itensSelected
  },
  state: () => {
    return {
      newWithdraw: {
        employee: undefined,
        item: undefined,
        reason: undefined,
        company_id: undefined,
        itensSelected: []
      }
    }
  },
  getters: {
    getValueItnsSelected: state => state.newWithdraw.itensSelected,
  },
  mutations: {
    reset (state) {
      state.newWithdraw.employee = undefined
      state.newWithdraw.item = undefined
      state.newWithdraw.reason = undefined
      state.newWithdraw.itensSelected = []
    },
    SET_COLLECT_VALUE (state, value) {
      state.newWithdraw.itensSelected.push(value)
    },
    REMOVE_ITEM_BY_COLLECT_VALUE (state, value) {
      state.newWithdraw.itensSelected.splice(value, 1)
    },
    CLEN_COLLECT (state) {
      state.newWithdraw.itensSelected = [];
    }
  },
  actions: {
    reset ({ commit }) {
      commit('reset')
    },
    cleanCollect ({ commit }) {
      commit('CLEN_COLLECT')
    },
    setCollectValue({ commit }, val) {
      commit('SET_COLLECT_VALUE', val);
    },
    removeItemByCollectValue({ commit }, val) {
      commit('REMOVE_ITEM_BY_COLLECT_VALUE', val);
    },
    updateItemByCollectValue({ commit }, val) {
      commit('UPDATE_ITEM_BY_COLLECT_VALUE', val);
    },
  }
})
