import { createStore } from 'vuex'

export const dataStock = createStore({
  state: () => {
    return {
      item: {
        name: undefined,
        description: undefined,
        size: undefined,
        amount: undefined,
        min_amount: undefined,
        approvalCertificate: {
          it_has_ca: false,
          number: undefined,
          validity: ''
        },
        unit: undefined,
        reusable: 1,
        maker: undefined,
        supplier: undefined,
        photo: undefined,
        imgPath: undefined,
        periodicity: undefined,
        company_id: localStorage.getItem('__company')
      }
    }
  },
  mutations: {
    reset (state) {
      state.item.amount = undefined
      state.item.min_amount = undefined
      state.item.approvalCertificate.number = undefined
      state.item.approvalCertificate.validity = undefined
      state.item.description = undefined
      state.item.size = undefined
      state.item.name = undefined
      state.item.photo = undefined
      state.item.reusable = undefined
      state.item.supplier = undefined
      state.item.maker = undefined
      state.item.unit = undefined
      state.item.photo = undefined
      state.item.imgPath = undefined
      state.item.periodicity = undefined
    }
  }

})
