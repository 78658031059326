import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/docs/'

class AsosService {
  static async getAllDocs () {
    return http.get(PATH.concat('all'))
  }

  static async getEmployeesByCombo () {
    return http.get(PATH.concat('employees'))
  }

  static async getAfastamentoCombo () {
    return http.get(PATH.concat('afastamento-combo'))
  }

  static async register (itensBody) {
    return http.post(PATH.concat('register-document'), itensBody)
  }

  static async uploadFile (doc) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company')
      }
    }

    return imgServe.post('new-file', { file: doc }, headers)
  }

  static async removeDocRegister(id) {
    return http.patch(PATH.concat(id).concat('/remove'))
  }

  static async removeDoc (imgPath) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company_path: imgPath,
        __company: localStorage.getItem('__company'),
      }
    }

    return imgServe.delete('remove', headers)
  }

}

export default AsosService
