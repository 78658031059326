<template>
  <div class="p-0 md:p-2 flex flex-col items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left block">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.employee.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.employee.imgPath"
                  alt="Funcionário"
                  class="mt-14 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                
                <!-- nome -->
                <div class="md:col-span-5">
                  <BaseInput
                    type="text"
                    name="name"
                    v-model="store.employee.name"
                    :info="'Nome completo do colaborador'"
                    label="Informe o nome"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.name = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="employee.name" class="text-red-600 text-sm" />
                </div>

                <!-- rg -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="rg"
                    max="10"
                    v-mask="'##.###.###-#'"
                    v-model="store.employee.document.rg"
                    :info="'Numero do RG'"
                    label="Informe o rg"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.document.rg = undefined"
                      />
                    </template>
                  </BaseInput>

                </div>

                <!-- cpf -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="cpf"
                    v-mask="'###.###.###-##'"
                    v-model="store.employee.document.cpf"
                    :info="'Numero do CPF'"
                    label="Informe o CPF"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.document.cpf = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.document.cpf"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- cnh -->
                <div class="md:col-span-1">
                  <BaseInput
                    name="cnh"
                    type="text"
                    v-model="store.employee.document.cnh"
                    :info="'CNH do tipo: '"
                    label="CNH"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.document.cnh = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.document.cnh"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- email -->
                <div class="md:col-span-3">
                  <BaseInput
                    name="email"
                    type="text"
                    v-model="store.employee.email"
                    :info="'E-mail do colaborador'"
                    label="e-mail "
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.email = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- celular -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="phone"
                    type="text"
                    v-model="store.employee.phone"
                    v-mask="'(##) # ####-####'"
                    :info="'Telefone para contato'"
                    label="Celular"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.phone = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="employee.phone" class="text-red-600 text-sm" />
                </div>

                <!-- função -->
                <div class="md:col-span-3">
                  <BaseInput
                    name="function"
                    type="text"
                    v-model="store.employee.function"
                    :info="'Função do colaborador'"
                    label="Informe sua função"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.function = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="employee.function" class="text-red-600 text-sm" />
                </div>

                <!-- admissão -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="registeredSince"
                    type="date"
                    v-model="store.employee.registeredSince"
                    :info="'Informe a data de admissão'"
                    label="Data da admissão"
                    :required-field="false"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.registeredSince = undefined"
                      />
                    </template>
                  </BaseInput>

                </div>

                <!-- cidade -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="employee.address.city"
                    type="text"
                    v-model="store.employee.address.city"
                    :info="'Cidade onde reside'"
                    label="Cidade"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.address.city = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.address.city"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- bairro -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="employee.address.country"
                    type="text"
                    v-model="store.employee.address.country"
                    :info="'Informe o bairro'"
                    label="Bairro"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.address.country = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.address.country"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- numero -->
                <div class="md:col-span-1">
                  <BaseInput
                    name="number"
                    type="text"
                    v-model="store.employee.address.number"
                    :info="'Informe o número'"
                    label="Numero"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.address.number = undefined"
                      />
                    </template>
                  </BaseInput>

                </div>

                <!-- fluxo da imagem -->
                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl || store.employee.imgPath"
                    :src="selectedFileUrl ?? fileUrl + store.employee.imgPath"
                    alt="Funcionário"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[10rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <!-- <i class="fa-solid fa-cloud-arrow-up"></i> -->
                    <div class="flex">
                      <PhCloudArrowUp class="h-6 w-6 ml-auto mr-auto" />
                      <p>
                        Foto do funcionário
                      </p>
                    </div>
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>

              </div>
            </div>

          </div>
        </div>

        <div class="p-2 mt-1 mb-1" />

        <div>
          <slot />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from '../components/CleanInputButton.vue'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataEmployee } from '../store/'
import BaseInput from '@/components/Inputs/BaseInput.vue';
import { PhCloudArrowUp } from '@phosphor-icons/vue';

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    BaseInput,
    PhCloudArrowUp
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataEmployee.state
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

    return {
      store,
      fileUrl,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.employee.photo = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.employee.photo = undefined
          return this.toast(
            'Protege informa: ',
            'Formato inválido, apenas imagens do tipo: webp, png, gif, jpeg e jpg são aceitos',
            'info'
          )
        }
      }
    }
  }
}
</script>
