<template>
  <div>
    <!-- header -->
    <header>
      <section
        class="w-full p-1 min-h-[7vh] flex justify-end border border-gray-300 rounded-2xl"
      >
        <div class="mt-auto mb-auto flex mr-2">
          <img
            class="w-11 h-11 rounded-full"
            src="https://salu.com.vc/wp-content/uploads/2022/11/exame-demissional-gestao-de-aso.png"
            alt="Jese image"
          />

          <div class="pl-2 mt-auto mb-auto text-left">
            <div class="text-base font-semibold">
              <!-- lengh -->
              {{ "Neil Sims" }}
            </div>

            <div class="font-normal text-gray-500 -mt-2">
              <small>
                {{ "Aux. de almoxarifado" }}
              </small>
            </div>
          </div>

          <div class="mt-auto mb-auto ml-2">
            <component :is="PhCaretDown" class="h-4 w-4" />
          </div>
        </div>
      </section>
    </header>

    <section class="px-3 pb-2">
      <!-- titlo e ações -->
      <div class="flex justify-between p-1 md:p-1 w-full">
        <!-- label -->
        <!-- <h2 class="text-3xl md:text-4xl mt-4 ml-3 p-3 border border-gray-300 rounded-md bg-orange-400">
          <slot />
        </h2> -->
        <div class="md:mt-0 text-left mt-auto mb-auto">
          <h1
            class="text-4xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-4xl"
          >
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r to-[#93cd24] from-[#0c7036]"
            >
              <slot />
            </span>
          </h1>
          <div class="w-full text-left">
            <small class="text-left font-normal text-gray-500">
              {{ props.subTitle }}
            </small>
          </div>
        </div>

        <!-- botão -->
        <div class="mt-auto mb-auto">
          <slot name="action" />
        </div>
      </div>

      <!-- <div class="w-full text-left">
        <small class="text-left font-normal text-gray-500">
          {{ props.subTitle }}
        </small>
      </div> -->
    </section>
  </div>
</template>

<script setup>
import { PhArrowDown, PhCaretDown } from "@phosphor-icons/vue";
import { defineProps } from "vue";

const props = defineProps({
  subTitle: {
    type: String,
    require: false,
  },
});
</script>
