<template>
  <div class="p-0 md:p-2 flex flex-col items-center justify-start min-h-[75vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left block mb-4">
              <p class="mb-2 font-medium text-lg text-gray-900">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                <!-- funcionário -->
                <div class="md:col-span-3">
                  <div class="md:col-span-2 mt-1.5">
                    <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                      Funcionário <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 flex rounded items-center"
                    >
                      <SimpleSelect
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        :data="employee"
                        @selected-data="(val) => (store.state.employee = val)"
                      />
                    </div>
                  </div>

                  <span
                    name="employee"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- periodo -->
                <div class="md:col-span-2">
                  <div class="md:col-span-2 mt-1.5">
                    <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                      Periodicidade <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 flex rounded items-center"
                    >
                      <InputSelect
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        :data="comboFields"
                        @selected-data="
                          (val) => (store.state.periodicity = val.value)
                        "
                      />
                    </div>
                  </div>

                  <span name="periodicity" class="text-red-600 text-sm" />
                </div>

                <!-- treinamentos -->
                <div class="md:col-span-3">
                  <div class="md:col-span-2 mt-1.5">
                    <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                      Informe o(s) treinamentos <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 flex rounded items-center"
                    >
                      <SimpleSelect
                        :data="training"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        @selected-data="(val) => setTrainingsCollection(val)"
                      />
                    </div>
                  </div>

                  <span name="trainings" class="text-red-600 text-sm" />
                </div>

                <!-- data de realização -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="date"
                    name="carried_out"
                    v-model="store.state.carried_out"
                    label="Realizado em"
                    :info="'Informe a data de realização'"
                    :required-field="true"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.state.carried_out = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="carried_out" class="text-red-600 text-sm" />
                </div>

              </div>
            
              <!-- advanced -->
              <div class="cursor-pointer mt-4">
                <div @click="toggleAdvancedFields">
                  <div class="flex justify-between items-center bg-gray-100 rounded p-1">
                    <label class="font-medium text-lg text-gray-800 p-1 -ml-1">
                      Opções Avançadas
                    </label>
                    <svg
                      :class="{'rotate-180': showAdvancedFields}"
                      class="w-6 h-6 transition-transform transform mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </div>

                <Transition mode="out-in">
                  <div v-show="showAdvancedFields" class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-2">
                    
                    <!-- datas -->
                    <div class="flex w-full md:col-span-5">
                      <!-- data inicial -->
                      <div class="md:col-span-2">
                        <BaseInput
                          name="date_start"
                          v-model="store.state.date_start"
                          v-mask="`##/##/####`"
                          label="Informe a data inicial"
                          :info="'Foi realizado em mais de uma data?'"
                          :required-field="false"
                          :activeDefault="true"
                        >
                          <template #append>
                            <CleanInputButton
                              @click="store.state.date_start = null"
                            />
                          </template>
                        </BaseInput>
                      </div>

                      <section class="mt-auto p-2 pb-0 text-base">
                        <small>
                          até
                        </small>
                      </section>
  
                      <!-- data final -->
                      <div class="md:col-span-2 md:ml-3">
                        <BaseInput
                          name="date_end"
                          v-model="store.state.date_end"
                          v-mask="`##/##/####`"
                          :info="'Data de finalização'"
                          label="Informe a data final"
                          :required-field="false"
                          :activeDefault="true"
                        >
                          <template #append>
                            <CleanInputButton
                              @click="store.state.date_end = null"
                            />
                          </template>
                        </BaseInput>
                      </div>
                    </div>

                    <!-- funcionário -->
                    <div class="md:col-span-2">
                      <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="newWithdraw.employee">
                        Período em dias
                      </label>
                      <div
                        class="h-10 flex rounded items-center mt-1 w-full"
                      >
                        <SimpleSelect
                          class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                          :data="period"
                          @selected-data="
                            (val) => (store.state.periods_in_day = val)
                          "
                        />
                      </div>

                      <span name="newWithdraw.employee" class="text-red-600 text-sm"/>
                    </div>

                  </div>
                </Transition>
              </div>

              <!-- coleção -->
              <div 
                v-if="dataTraining?.getters?.getValueByTrainingsCollect.length" 
                class="mb-2 w-full p-1"
              >

                <div class="w-full flex justify-between text-gray-400 pt-2">
                  <small class="w-full mt-auto mb-auto text-left font-medium">
                    TREINAMENTOS INFORMADOS:
                  </small>
                </div>

                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataTrainings"
                  :key="index"
                >
                  <ArrayItems
                    :item="item"
                    @removeItem="removeselectedItems(index)"
                  />
                </div>
              </div>
            </div>

          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <!-- <FloatBackPage /> -->
  </div>
</template>

<script setup>
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import CleanInputButton from '../utils/CleanInputButton.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import TrainingService from '../service/training.service'
import InputSelect from './../utils/InputSelect.vue'
import ArrayItems from '../utils/ArrayItems.vue'
import { daysToMonth } from '../utils/_partials/calcDayToMonth'
import { onMounted, ref, watch } from 'vue'
import { dataTraining } from '../store'
import { period } from '../lookups/form';
import { computed } from 'vue';

defineProps({
  descriptionTitle: String,
  training: [Object, Array],
  employee: [Object, Array]
})

const store = dataTraining
const dataTrainings = computed(() => dataTraining.getters.getValueByTrainingsCollect)
const comboFields = ref([''])
const showAdvancedFields = ref(false)

function toggleAdvancedFields () {
  showAdvancedFields.value = ! showAdvancedFields.value
}

watch(comboFields.value, setPeriodicitiesFields)

onMounted(async () => {
  await setPeriodicitiesFields()
})

function removeselectedItems (index) {
  // dataTrainings.value.splice(index)
  dataTraining.dispatch('removeItemByCollectValue', index);
}

function setTrainingsCollection (training) {
  dataTraining.dispatch('setCollectValue', training);
  store.state.trainings = dataTraining.getters.getValueByTrainingsCollect
  // console.log(training)
  // dataTrainings.value.push(training)
}

async function setPeriodicitiesFields () {
  comboFields.value = []
  await TrainingService.getPeriods().then((res) => {
    res.data.forEach((p) => {
      const setMonths = daysToMonth(p.validity)
      comboFields.value.push({
        label:
          setMonths >= 6
            ? `Período de ${setMonths} meses`
            : `Período de ${setMonths} anos`,
        value: p.id
      })
    })
  })
}
</script>
