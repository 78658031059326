<template>
  <div>
    <PageTitleVue>
      Minha Empresa
    </PageTitleVue>
    <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css">

    <!-- <ContainerVue> -->
      <div>
        <section>
          <div class="container mx-auto px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-1">
              <div class="px-6">
                <div class="flex flex-wrap justify-center">

                  <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div class="relative">
                      <img style="width: 150px; height: 150px;" width="800" height="800"
                        :src="store.state.imgPath ? imgUrl + store.state.imgPath : imgUrl + 'protege-rm-bg.webp'"
                        class="shadow-xl rounded-full align-middle border-none absolute -m-10 -ml-20 lg:-ml-16 max-w-150-px">
                    </div>
                  </div>

                  <div class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div class="py-6 px-3 mt-28 sm:mt-0">
                      <div class="px-2 py-2 flex justify-center md:justify-end">
                        <LinkButton :link="editUrl" :icon="'fa-solid fa-user-pen'" :label="'Editar'" />
                      </div>
                    </div>
                  </div>

                  <div class="w-full lg:w-4/12 px-4 lg:order-1 ">
                    <div class="flex justify-center py-4 lg:pt-4 pt-1">
                      <div class="mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          150
                        </span>
                        <span class="text-sm text-blueGray-400">
                          Funcionários
                        </span>
                      </div>
                      <div class="mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          25
                        </span>
                        <span class="text-sm text-blueGray-400">
                          EPI's
                        </span>
                      </div>
                      <div class="lg:mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          4
                        </span>
                        <span class="text-sm text-blueGray-400">
                          Usuários
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center md:mt-10">
                  <h3 class="text-4xl font-semibold leading-normal mb-1 text-blueGray-700">
                    {{ store.state.name }}
                  </h3>
                  <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i class="fa-solid fa-passport mr-2 text-lg text-blueGray-400"></i>
                    {{ store.state.cnpj }}
                  </div>
                </div>

                <div class="mt-5 border-t border-blueGray-200 text-center">
                  <div class="flex flex-wrap justify-center">
                    <div class="text-align-block w-full">
                      <div class="md:text-start md:w-1/3">
                        <div class="mb-2 text-blueGray-600 mt-4">
                          <p>
                            <i class="fa-solid fa-tree-city mr-2 text-lg text-blueGray-400"></i>
                            {{ store.state.city }}
                          </p>
                        </div>
                        <div class="mb-2 text-blueGray-600">
                          <p>
                            <i class="fa-solid fa-house mr-2 text-lg text-blueGray-400"></i>
                            {{ store.state.address }}
                          </p>
                        </div>
                        <div class="mb-4 text-blueGray-600">
                          <p>
                            <i class="fa-solid fa-location-dot pl-1 mr-2 text-lg text-blueGray-400"></i>
                            {{ store.state.country }}, n°{{ store.state.number }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    <!-- </ContainerVue> -->
  </div>
</template>

<script setup>
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CompanyService from './services/company.service'
import LinkButton from '@/components/Buttons/Link.vue'
import ContainerVue from '@/components/Container.vue'
import { showToast as toast } from '@/helpers/UseToasts'
import { onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import { dataUser } from './store'

const appStore = storeApp
const editUrl = '/company/'.concat(appStore.state.accessToken.getItem('__uuid')).concat('/edit')
const store = dataUser
const imgUrl = ref(process.env.VUE_APP_IMAGE_SERVE.concat('storage/'))

onMounted(async () => {
  store.commit('reset')
  appStore.state.loading = true
  await getDataCompany()
  appStore.state.loading = false
})

async function getDataCompany () {
  appStore.loading = true
  await CompanyService.getMeCompany(appStore.state.accessToken.getItem('__uuid'))
    .then((res) => {
      store.state.name = res.data.name
      store.state.cnpj = res.data.CNPJ
      store.state.city = res.data.city
      store.state.address = res.data.address
      store.state.country = res.data.country
      store.state.number = res.data.number
      store.state.imgPath = res.data.photo

      appStore.state.loading = false
    })
    .catch((err) => {
      appStore.loading = false

      return toast(
        'Aviso',
        'Dados da empresa não encontrados',
        'error'
      )
    })
}

</script>

<style lang="scss" scoped>
.text-align-block {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
}
</style>
