<template>
  <div class="p-0 md:p-2 flex flex-col items-center justify-center" :key="store.trainingForm">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            
            <div class="text-left block mb-4">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ props.formCreate.descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div class="lg:col-span-2" id="form-fields">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                
                <!-- titulo do treinamento -->
                <div class="md:col-span-3">
                  <BaseInput
                    type="text"
                    name="name"
                    v-model="store.trainingForm.name"
                    label="Treinamento de"
                    :info="'Informe o título do treinamento'"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.trainingForm.name = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="name" class="text-red-600 text-sm" />
                </div>

                <!-- palestrante -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="speaker"
                    v-model="store.trainingForm.speaker"
                    :info="'Nome do Instrutor ou Palestrante'"
                    label="Instrutor ou Palestrante"
                    :required-field="true"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.trainingForm.speaker = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="speaker" class="text-red-600 text-sm" />
                </div>

                <!-- nr -->
                <div class="md:col-span">
                  <BaseInput
                    type="text"
                    name="nr"
                    v-model="store.trainingForm.nr"
                    :info="'Qual NR?'"
                    label="NR"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.trainingForm.nr = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="nr" class="text-red-600 text-sm" />
                </div>

                <!-- categoria -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="type">
                    Categoria
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-2 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      :data="combo.category"
                      :current="getTypeByKey(combo.category, store.trainingForm.type) ?? 0"
                      @selected-data="(val) => (store.trainingForm.type = val.value)"
                    />
                  </div>
                </div>

                <!-- tipo -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="contentType">
                    Tipo do treinamento
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-2 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      :data="combo.contentType"
                      :current="getContentType(combo.contentType, store.trainingForm.contentType) ?? 0"
                      @selected-data="
                        (val) => (store.trainingForm.contentType = val.value)
                      "
                    />
                  </div>
                </div>

                <!-- carga horária -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="number"
                    name="workload"
                    v-model="store.trainingForm.workload"
                    :info="'Informe a duração desse treinamento'"
                    label="Carga horária"
                    :required-field="true"
                    :activeDefault="false"
                    :center="true"
                  >
                    <template #append>

                      <section class="mt-auto mb-auto">
                        <p>
                          horas
                        </p>
                      </section>

                      <CleanInputButton
                        @click="store.trainingForm.workload = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="workload" class="text-red-600 text-sm" />
                </div>

                <!-- periodicidade -->
                <div class="md:col-span-2">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                    Periodicidade <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 flex rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      :data="combo.periodicity"
                      :current="getPeriodicityByKey(combo.periodicity, store.trainingForm.period?.id) - 1 ?? 0"
                      @selected-data="
                        (val) => (store.trainingForm.periodicity_id = val.value)
                      "
                    />
                  </div>

                  <span name="trainingForm.periodicity_id" class="text-red-600 text-sm" />
                </div>

              </div>
            </div>

            <div class="p-2 mt-1 mb-1" />

            <div class="w-full flex justify-center mt-8">
              <CreateButton
                @click="checkFormType()"
                :icon="PhStackPlus"
                :label="props.formCreate.labelButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from '../utils/CleanInputButton.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import SimpleSelect from './../utils/InputSelect.vue'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import { category, contentType } from '../lookups/form'
import { defineProps, defineEmits } from 'vue'
import { dataTraining } from '../store'
import { storeApp } from '@/store'
import * as yup from './../../../validator'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import {
  PhStackPlus
} from "@phosphor-icons/vue";

const appStore = storeApp.state
const store = dataTraining.state
const emit = defineEmits()
const schema = yup.object().shape({
  name: yup.string().required().label('Titulo do treinamento'),
  workload: yup.string().required().label('Carga horária'),
  periodicity_id: yup.number().required().label('Periodicidade'),
  type: yup.string().required().label('Categoria'),
  contentType: yup.string().required().label('Tipo do treinamento')
})

const props = defineProps({
  periodicityField: [Array, Object],
  formCreate: Object
})

const combo = {
  contentType: contentType,
  category: category,
  periodicity: props.periodicityField
}

function getPeriodicityByKey (comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == Number(value)) {
      store.trainingForm.periodicity_id = el.value
      return el.value
    }
  }
  return null
}

function getTypeByKey (comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == value) { return index }
  }
  return null
}

function getContentType (comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == value) { return index }
  }
  return null
}

function checkFormType () {
  if (props.formCreate.create) { return registerTraining() }

  return updateTraining()
}

async function updateTraining () {
  const errors = []
  await schema
    .validate(store.trainingForm, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((e) => {
        errors.push({
          name: e.path,
          msg: e.message
        })
      })
    })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  emit('update', store.trainingForm.id)
}

async function registerTraining () {
  store.trainingForm.type =
    store.trainingForm.type || combo.category[0].value

  store.trainingForm.contentType =
    store.trainingForm.contentType || combo.contentType[0].value

  store.trainingForm.periodicity_id =
    store.trainingForm.periodicity_id || combo.periodicity[0].value

  const errors = []
  await schema
    .validate(store.trainingForm, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((e) => {
        errors.push({
          name: e.path,
          msg: e.message
        })
      })
    })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  emit('register')
}

</script>
