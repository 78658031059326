import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/itens/'

class StockService {
  async register (itensBody, confirm) {
    const headers = {
      headers: {
        // valor que atualiza após a notificação de duplicidade
        __confirm_created: confirm
      }
    }

    return http.post(PATH.concat('new'), itensBody, headers)
  }

  async generateStockReport (id) {
    if (!id) { return false }

    return http.get(PATH.concat('reports/').concat(id))
  }

  async saveImage (newImg) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company')
      }
    }

    return imgServe.post('new-file', { file: newImg }, headers)
  }

  async editImage (newImg, imgPath) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company'),
        __company_path: imgPath
      }
    }

    return imgServe.post('replace-file', { file: newImg }, headers)
  }

  async editIten (id, itenBody) {
    return http.patch(PATH.concat(id).concat('/edit'), itenBody)
  }

  async getAllItens () {
    return http.get(PATH.concat('all'))
  }

  async getItenById (id) {
    return http.get(PATH.concat(id).concat('/iten'))
  }

  async deleteIten (id) {
    return http.patch(PATH.concat(id).concat('/disable'))
  }
}

export default new StockService()
export { PATH }
