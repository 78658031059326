<template>
  <div class="-mt-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                {{ descriptionSubTitle }}
              </p>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <!-- nome da mãe -->
                <div class="md:col-span-3">
                  <BaseInput
                    name="mother"
                    v-model="store.employee.mother"
                    :info="'Nome completo da mãe'"
                    label="Nome da mãe"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.mother = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- nome do pai -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="father"
                    v-model="store.employee.father"
                    :info="'Nome completo do pai'"
                    label="Nome do pai"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.father = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="employee.father" class="text-red-600 text-sm" />
                </div>

                <!-- pis -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="pis"
                    v-mask="'###.#####.##-#'"
                    v-model="store.employee.pis"
                    :info="'PIS'"
                    label="Número do pis"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.pis = null"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.pis"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- ctps -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="ctps"
                    v-mask="'#######/####'"
                    v-model="store.employee.ctps"
                    :info="'CTPS'"
                    label="Número do CTPS"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.ctps = null"
                      />
                    </template>
                  </BaseInput>

                  <span
                    name="employee.pis"
                    class="text-red-600 text-sm"
                  />
                </div>

                <!-- serie -->
                <div class="md:col-span-1">
                  <BaseInput
                    name="serie"
                    v-mask="'###############'"
                    v-model="store.employee.serie"
                    :info="'Serie'"
                    label="Serie"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.serie = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- demissao -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="demissao"
                    type="date"
                    v-model="store.employee.demissao"
                    :info="'Informe a data de demissão'"
                    label="Data de demissão"
                    :required-field="false"
                    :activeDefault="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.demissao = null"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- e-social -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="esocial"
                    v-model="store.employee.esocial"
                    v-mask="'##############'"
                    :info="'Matricula e-Social'"
                    label="Matricula do e-Social"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.esocial = undefined"
                      />
                    </template>
                  </BaseInput>
                  <span name="employee.esocial" class="text-red-600 text-sm" />
                </div>

                <!-- cbo -->
                <div class="md:col-span-2">
                  <BaseInput
                    name="cbo"
                    v-mask="'####-##'"
                    v-model="store.employee.cbo"
                    :info="'CBO'"
                    label="Informe o CBO"
                    :required-field="false"
                    :activeDefault="false"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.employee.cbo = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- piso_salarial -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="text"
                    name="employee.piso_salarial"
                    v-model="store.employee.piso_salarial"
                    :info="'Piso salarial'"
                    label="Informe o piso salarial"
                    :required-field="false"
                    :activeDefault="false"
                    @input="formatCurrency"
                  >
                    <template #append>
                      <CleanInputButton @click="store.employee.piso_salarial = undefined" />
                    </template>
                  </BaseInput>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="p-2 mt-1 mb-1" />

        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CleanInputButton from "../components/CleanInputButton.vue";
import { dataEmployee } from "../store/";
import { onMounted } from "vue";

defineProps({
  descriptionTitle: String,
  descriptionSubTitle: String,
});

const store = dataEmployee.state;
const freeLancerActive = [
  {
    name: "SIM",
    value: "1",
  },
  {
    name: "NÂO",
    value: "0",
  },
];
const shiftHours = [
  {
    name: "PERIODO INTEGRAL",
    value: "FULL_TIME",
  },
  {
    name: "APENAS DE MANHÃ",
    value: "MORNING_ONLY",
  },
  {
    name: "APENAS DE TARDE",
    value: "AFTERNOON_ONLY",
  },
  {
    name: "FREE LANCER",
    value: "FREE_LANCER",
  },
];

function formatCurrency (event) {
  let rawPrice = ''
  const onlyDigits = event.target.value.replace(/\D/g, '')

  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(onlyDigits / 100)

  store.employee.piso_salarial = formatted
  rawPrice = onlyDigits / 100
}
</script>
