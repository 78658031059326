<template>
  <div>
    <RouterView class="router-view" v-slot="{ Component = AsosList }">
      <Transition mode="out-in">
        <component
          :is="currentComponent || Component"
          :dataTable="dataTrainings"
        />
          <!-- @getDataByTraining="getTrainingById"
          @register="addTraining"
          @update="updateTraining"
          @remove="removeTraining" -->
      </Transition>
    </RouterView>
  </div>

</template>

<script setup name="list">
import AsosList from './pages/AsosList.vue';
import { dataAso } from '../store';
import { computed } from 'vue';

const currentComponent = computed(() => dataAso.getters.getActiveComponent)


</script>

<style scoped lang="scss">
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
