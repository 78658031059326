import { createStore } from 'vuex'

export const dataTraining = createStore({
  state: () => {
    return {
      trainings: undefined,
      employee: undefined,
      periodicity: undefined,
      carried_out: null,
      trainingsCollect: [],
      
      // opções avançadas
      date_start: null,
      date_end: null,
      periods_in_day: null,

      // formulário de cadastro
      trainingForm: {
        name: undefined,
        nr: undefined,
        speaker: undefined,
        workload: undefined,
        periodicity_id: undefined,
        type: undefined,
        contentType: undefined
      },
      employeeTrainings: [],
      modalEmployeesIsOpen: false,
      filter: {
        uniqueRoles: [], 
      },
      modalData: {}
    }
  },
  getters: {
    getModalEmployeesState: (state) => state.modalEmployeesIsOpen,
    getEmployeeTrainings: (state) => state.employeeTrainings,
    setRolesByFilter: (state) => state.filter.uniqueRoles,
    getModalData: (state) => state.modalData,
    getValueByTrainingsCollect: (state) => state.trainingsCollect,
  },
  mutations: {
    reset (state) {
      state.trainings = undefined
      state.employee = undefined
      state.periodicity = undefined
      state.carried_out = undefined
      
      state.date_start = null
      state.date_end = null
      state.periods_in_day = null
      
      state.trainingForm.name = undefined
      state.trainingForm.nr = undefined,
      state.trainingForm.speaker = undefined
      state.trainingForm.workload = undefined
      state.trainingForm.periodicity_id = undefined
      state.trainingForm.type = undefined
      state.trainingForm.contentType = undefined
      state.trainingForm.period = undefined
    },
    SET_EMPLOYEE_TRAININGS (state, value) {
      state.employeeTrainings = value
    },
    ENABLE_MODAL_SCHEDULES (state) {
      state.modalEmployeesIsOpen = true
    },
    DISABLE_MODAL_SCHEDULES (state) {
      state.modalEmployeesIsOpen = false
    },
    SET_ROLES_BY_FILTER (state, value) {
      state.filter.uniqueRoles = value
    },
    SET_MODAL_DATA (state, value) {
      state.modalData = value;
    },
    SET_COLLECT_VALUE (state, value) {
      state.trainingsCollect.push(value)
    },
    REMOVE_ITEM_BY_COLLECT_VALUE (state, value) {
      state.trainingsCollect.splice(value, 1)
    },
    CLEN_COLLECT (state) {
      state.trainingsCollect = [];
    }
  },
  actions: {
    setEmployeeTrainings({ commit }, value) {
      commit('SET_EMPLOYEE_TRAININGS', value);
    },
    disableModalEmployees({ commit }) {
      commit('DISABLE_MODAL_SCHEDULES');
    },
    enableModalEmployees({ commit }) {
      commit('ENABLE_MODAL_SCHEDULES');
    },
    setRolesByFilter({ commit }, value) {
      commit('SET_ROLES_BY_FILTER', value);
    },
    setModalData({ commit }, value) {
      commit('SET_MODAL_DATA', value);
    },
    setCollectValue({ commit }, val) {
      commit('SET_COLLECT_VALUE', val);
    },
    removeItemByCollectValue({ commit }, val) {
      commit('REMOVE_ITEM_BY_COLLECT_VALUE', val);
    },
    cleanCollect ({ commit }) {
      commit('CLEN_COLLECT');
    },
  }
})
