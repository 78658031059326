import authService from '@/modules/auth/services/auth.service'
import { createRouter, createWebHistory } from 'vue-router'
import { errorsPage } from '@/modules/errors/route'
import { showToast as toast } from '@/helpers/UseToasts'
import { storeApp } from '@/store'
import { http } from '@/http'

// rotas
import { employeesRoute } from '@/modules/employees/routes'
import { trainingRoute } from '@/modules/trainings/routes'
import { profileRoute } from '@/modules/profile/routes'
import { companyRoute } from '@/modules/company/routes'
import { stockRoute } from '@/modules/stock/routes'
import { loginRoute } from '@/modules/auth/routes'
import { homeRoute } from '@/modules/home/routes'
import { asosRoute } from '@/modules/asos/routes'

const routes = [
  ...homeRoute,
  ...stockRoute,
  ...employeesRoute,
  ...loginRoute,
  ...profileRoute,
  ...companyRoute,
  ...errorsPage,
  ...trainingRoute,
  ...asosRoute,
]

const router = createRouter({
  history: createWebHistory('#'),
  routes
})

router.beforeEach(async (to, from, next) => {
  storeApp.state.loading = true

  if (to.name === 'logout') {
    return authService.logout()
      .then((res) => {
        localStorage.clear();

        storeApp.dispatch('hiddenMenuUserLoggedOut')
        storeApp.dispatch('setUserAuthenticated', false)

        toast(
          'Até logo!',
          'Deslogado com sucesso',
          'success'
        )

        next({ name: 'login' })
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Sessão encerrada',
          'error'
        )

        localStorage.clear();
        next({ name: 'login' })
      })
  }

  if (to.name !== 'login') {
    try {
      await http.get(process.env.VUE_APP_API_URL.concat('auth/me'))
      storeApp.state.loading = false
      next()
    } catch (error) {
      toast(
        'Aviso',
        'Sua sessão expirou',
        'error'
      )

      localStorage.clear();
      storeApp.dispatch('hiddenMenuUserLoggedOut')
      storeApp.dispatch('setUserAuthenticated', false)
      storeApp.state.loading = false

      next({ name: 'login' })
    }
  } else {
    storeApp.dispatch('setUserAuthenticated', false)
    storeApp.state.accessToken.clear()
    storeApp.state.loading = false
    next()
  }
})

export default router
