<template>
  <div>
    <PageTitleVue> Dados da empresa </PageTitleVue>
    <!-- <ContainerVue> -->
      <Form id="form-fields" :description-title="'Dados da empresa'">
        <NotifyForm />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="saveData"
            :icon="'fa-solid fa-check'"
            :label="'Salvar'"
          />
        </div>
      </Form>
    <!-- </ContainerVue> -->
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import Form from './Form.vue'
import NotifyForm from './NotifyForm.vue'
import ContainerVue from '@/components/Container.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import { onMounted } from 'vue'
import { dataUser } from '../store'
import { storeApp } from '@/store'
import { useRoute } from 'vue-router'
import { showToast as toast } from '@/helpers/UseToasts'
import CompanyService from '../services/company.service'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import * as yup from './../../../validator'

const store = dataUser
const appStore = storeApp.state
const meUuid = useRoute()

const schema = yup.object().shape({
  company: yup.object().shape({
    name: yup.string().required().label('Nome Fantasia').max(100).min(2),
    cnpj: yup.string().required().label('CNPJ').min(18),
    city: yup.string().required().label('Cidade'),
    number: yup.number().integer().required().label('Número').min(1)
  }),
  notifications: yup.object().shape({
    email: yup.string().label('Email').email().required().max(35).min(11),
    period: yup
      .number()
      .integer()
      .label('Período')
      .required()
      .integer()
      .moreThan(0)
  })
})

onMounted(async () => {
  appStore.loading = true
  store.commit('reset')
  await Promise.all([getDataCompany(), getNotifyData()])
  appStore.loading = false
})

async function getNotifyData () {
  await CompanyService.getNotificationData(meUuid.params.id)
    .then((res) => {
      store.state.notifications.email = res.data.email
      store.state.notifications.period = res.data.period
    })
    .catch((err) => {
      toast(
        'Protege informa: ',
        'Informe um periodo para receber notificações sobre sua empresa',
        'info'
      )
    })
}

async function getDataCompany () {
  await CompanyService.getMeCompany(meUuid.params.id)
    .then((res) => {
      store.state.company.name = res.data.name
      store.state.company.cnpj = res.data.CNPJ
      store.state.company.city = res.data.city
      store.state.company.address = res.data.address
      store.state.company.country = res.data.country
      store.state.company.number = res.data.number
      store.state.imgPath = res.data.photo
    })
    .catch((err) => {
      toast(
        'Aviso',
        'Dados da empresa não encontrados',
        'error'
      )
    })
}

async function saveData () {
  appStore.loading = true

  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  await Promise.all([editNotifyData(), editCompany()])
  appStore.loading = false
}

async function editNotifyData () {
  store.state.notifications.user_id = meUuid.params.id
  await CompanyService.updateNotificationData(store.state.notifications)
    .then((res) => {
      if (res.data.success) {
        toast(
          'Sucesso',
          'Periodo de notificações atualizado',
          'success'
        )
      }
    })
    .catch((err) => {
      toast(
        'Aviso',
        'Seu e-mail não foi informado',
        'error'
      )
    })
}

async function editCompany () {
  if (
    store.state.company.photo &&
    typeof store.state.company.photo === 'object' &&
    typeof store.state.imgPath === 'string' &&
    store.state.imgPath !== '[]'
  ) {
    await CompanyService.editImage(
      store.state.company.photo,
      store.state.imgPath
    )
      .then((res) => {
        store.state.imgPath = res?.data
        store.state.company.photo = res?.data
        localStorage.__company_logo = res?.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de novo arquivo não concluído',
          'error'
        )
      })
  } else if (
    store.state.company.photo &&
    typeof store.state.company.photo !== 'string'
  ) {
    await CompanyService.saveImage(store.state.company.photo)
      .then((res) => {
        store.state.imgPath = res.data
        store.state.company.photo = res.data
        localStorage.__company_logo = res.data
      })
      .catch((err) => {
        toast(
          'Aviso',
          'Upload de arquivo não concluído',
          'error'
        )
      })
  } else {
    store.state.company.photo = store.state.imgPath
  }

  await CompanyService.updateMeCompany(meUuid.params.id, store.state.company)
    .then((res) => {
      if (res.data.success) {
        toast(
          'Sucesso',
          'Dados da empresa atualizados',
          'success'
        )
      }
    })
    .catch((err) => {
      toast(
        'Aviso',
        'As alteraçoes não foram salvas',
        'error'
      )
    })
}
</script>

<style></style>
