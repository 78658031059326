import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/'

class HomeService {
  async newWithdraw (requestBody) {
    return await http.post(PATH.concat('new-withdraw'), requestBody)
  }

  async generateWithdrawReport (reportId) {
    return http.get('app/withdraw/reports/'.concat(reportId))
  }

  // home
  async getRecentWithdraw () {
    return await http.get(PATH.concat('recent-withdrawals'))
  }

  async getLowStock () {
    return await http.get(PATH.concat('low-stock'))
  }

  // home
  async getRetreatChampions () {
    return await http.get(PATH.concat('retreat-champions'))
  }
}

export default new HomeService()
export { PATH }
