<template>
  <div>
    <Form
      :description-title="'Faça o upload de novos documentos'"
    >
      <CreateButton
        @click="uploadDoc()"
        :icon="PhCloudCheck"
        :label="'Registrar'"
      />
    </Form>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import Form from './../../components/Form.vue'
import { cleanAllFormFields, setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { showToast as toast } from '@/helpers/UseToasts'
import { dataAso } from './../../store'
import { storeApp } from '@/store'
import { onMounted } from 'vue'
import * as yup from '@/validator'
import {
  PhCloudCheck
} from "@phosphor-icons/vue";
import AsosService from '../../service/asos.service'

const store = dataAso.state
const appStore = storeApp.state

const schema = yup.object().shape({
  form: yup.object().shape({
    employee: yup.object().required('Informe o funcionário').label('Funcionário'),
    expired_at: yup.string().required('Informe a data de vencimento').label('Data final'),
    type: yup.string().required('Informe o tipo do documento').label('Tipo'),
    docName: yup.string().required('Selecione um documento').label('Documento'),
  })
})

async function uploadDoc () {
  appStore.loading = true

  cleanAllFormFields()

  const validate = (await validateFormField(schema, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.loading = false
    return
  }

  // upload file
  Promise.allSettled([
    AsosService.uploadFile(store.form.doc)
      .then(res => {
        appStore.loading = false

        toast(
          'Sucesso',
          'Seu arquivo foi registrado',
          'success'
        )
      }).catch(err => {
        toast(
          'Aviso',
          'Seu arquivo não foi salvo',
          'error'
        )
      })
  ])

  delete store.form.doc
  store.form.docOriginalName = store.form.docName
  store.form.docName = localStorage.getItem('__company').concat('/docs/') + store.form.docName

  await AsosService.register(store.form)
    .then(res => {
      if (res?.data?.success && res?.data?.data) {
        dataAso.dispatch('resetForm')
        dataAso.dispatch('setAllDocs', res?.data?.data)
      }
    }).catch(err => {
      appStore.loading = false

      if (err?.response?.data?.error && err.response.data.status === 400) {
        return toast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      return toast(
        'Aviso',
        'Algo deu errado ao registrar documento',
        'error'
      )
    })
}

onMounted(async () => {
  appStore.loading = true
  dataAso.commit('reset');
  appStore.loading = false
})

</script>
